import { ChangeEvent } from "react"
import { parse, format, parseISO, isValid } from "date-fns"

export function formatPhoneNumber(value: string): string {
  const cleaned = String(value).replace(/\D/g, "")
  return (value = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3"))
}

export function unformatPhoneNumber(num: string): string {
  const cleaned = ("" + num).replace(/\D/g, "")
  return cleaned
}

function deepCopy<T>(obj: T): T {
  if (obj === null || typeof obj !== "object") return obj
  const copy: any = {}
  for (const key in obj) {
    if (obj[key] === null || obj[key] === undefined) {
      copy[key] = ""
    } else {
      copy[key] = deepCopy(obj[key])
    }
  }
  return copy as T
}

function formatNumber(value: string | number, options = { decimals: 2, maxLength: 7 }): string | number {
  value = String(value)
  const maxLength = !!options.decimals ? options.maxLength + 3 : options.maxLength
  if (value.length > maxLength) return value.slice(0, -1)
  let seen = false
  let seenMinus = false
  // @ts-ignore
  if (isNaN(value))
    return value
      .replace(/[.]/g, () => {
        if (seen) return ""
        seen = true
        return "."
      })
      .replace(/^-/g, () => {
        if (seenMinus) return ""
        seenMinus = true
        return "-"
      })
      .replace(/[^0-9.-]/g, "")
  if (value.indexOf(".") == -1) return value
  if (value.length - value.indexOf(".") > options.decimals) value = Number(value).toFixed(options.decimals)
  return value
}

interface FormatNumberPropsV2 {
  decimals?: number
  integers: number
}

export function formatNumberV2(value: any, options: FormatNumberPropsV2 = { decimals: 0, integers: 7 }): string | number {
  value = String(value)
  const maxLength = !!options.decimals ? options.integers + options.integers : options.integers
  if (value.length > maxLength) return value.slice(0, -1)
  if (!options.decimals) {
    return value.replace(/[.]/g, "").replace(/[^0-9.-]/g, "")
  }
  if (value.length > options.integers) {
    const integer = value.split(".")[0]
    const decimal = value.split(".")[1]
    if (integer.length > options.integers) {
      return integer.slice(0, -1)
    }
    if (decimal.length > options.decimals) {
      return `${integer}.${decimal.slice(0, -1)}`
    }
  }
  console.log("Entry")
  let seen = false

  if (isNaN(value))
    return value
      .replace(/[.]/g, () => {
        if (seen) return ""
        seen = true
        return "."
      })
      .replace(/(?!^)-/g, "")
      .replace(/[^0-9.-]/g, "")
  if (value.indexOf(".") == -1) return value
  if (value.length - value.indexOf(".") > options.decimals) value = Number(value).toFixed(options.decimals)
  return value
}

const INPUT_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
const OUTPUT_FORMAT = "MM-dd-yyyy"
export function formatDate(currDate: string) {
  if (!currDate) return "-"
  const dateObject = parse(currDate, INPUT_FORMAT, new Date())
  return isValid(dateObject) ? format(dateObject, OUTPUT_FORMAT) : "-"
}

export function formatTime(currDate: string) {
  if (!currDate) return "-"
  const newDate = parseISO(currDate)
  if (!isValid(newDate)) return "-"
  const dateObject = parse(currDate, "yyyy-MM-dd HH:mm:ss", new Date())
  return format(dateObject, "HH:mm:ss")
}

export function timestampToDate(timestamps: string) {
  if (!timestamps) return "-"
  const dateISO = parseISO(timestamps)
  return format(dateISO, "MM-dd-yyyy HH:mm:ss")
}

export function dateToStringShort(_date: string) {
  if (!_date) return "-"
  const newDate = parseISO(_date)
  if (!isValid(newDate)) return "-"
  return format(newDate, "EEE, d MMM yyyy hh:mm a")
}

export const getInitials = (text: string, count?: number) => {
  const splitted = text.split(" ")
  const splitCount = count ?? splitted.length
  return splitted
    .map((word) => String(word).toLocaleUpperCase().charAt(0))
    .slice(0, splitCount)
    .join("")
}

export const sleep = async (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

const toCapitalize = (str: string) => str[0].toUpperCase() + str.slice(1)

export function tryParseJSONObject(jsonString: string): boolean | any {
  try {
    const o = JSON.parse(jsonString)
    if (o && typeof o === "object") return o
    return false
  } catch (e) {
    return false
  }
  return false
}

export const currency = (value: number | string): string => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Number(value))
}

const getValueAccordingMinMax = (ev: ChangeEvent<HTMLInputElement>): any => {
  let value: string | number = ev.target.value
  const attrMin = ev.target.min
  const attrMax = ev.target.max
  if (!attrMin && !attrMax) return value
  value = Number(value)
  if (attrMin && value < +attrMin) return attrMin
  if (attrMax && value > +attrMax) return attrMax
  return value
}

interface GlobalConfigFormat {
  uppercase: boolean
}

const globalConfigFormatDefault: GlobalConfigFormat = {
  uppercase: true,
}

const toNumbers = (text: string, config: Partial<GlobalConfigFormat> = {}) => {
  const { uppercase } = { ...globalConfigFormatDefault, ...config }
  const numericString = text.replace(/[^0-9]/g, "")
  if (uppercase) return numericString.toLocaleUpperCase()
  return numericString
}

const toPlate = (text: string, config: Partial<GlobalConfigFormat> = {}) => {
  const { uppercase } = { ...globalConfigFormatDefault, ...config }
  const numericString = text.replace(/[^a-zA-Z0-9-]|[-]{2,}/g, "")
  if (uppercase) return numericString.toLocaleUpperCase()
  return numericString
}

const toNumberWithDashes = (text: string, config: Partial<GlobalConfigFormat> = {}) => {
  const { uppercase } = { ...globalConfigFormatDefault, ...config }
  const numericString = text.replace(/[^0-9-]|[-]{2,}/g, "")
  if (uppercase) return numericString.toLocaleUpperCase()
  return numericString
}

const toLettersWithSpaceDashes = (text: string, config: Partial<GlobalConfigFormat> = {}) => {
  const { uppercase } = { ...globalConfigFormatDefault, ...config }
  const numericString = text
    .replace(/[^a-zA-Z\s-]/g, "")
    .replace(/\s{2,}/g, " ")
    .replace(/-+/g, "-")
  if (uppercase) return numericString.toLocaleUpperCase()
  return numericString
}

const removeRepeatChars = (text: string, chars: RegExp[]) => {
  let textNormalize = text
  chars.forEach((char) => {
    textNormalize = textNormalize.replace(char, (match) => match[0])
  })
  return textNormalize
}

interface toWithDynamicArgs {
  (
    props: {
      text: string
      type?: "number" | "string" | "numbers-with-letters"
      allowedChars?: string
      allowOneSpacePerWord?: boolean
    },
    config?: Partial<GlobalConfigFormat>,
  ): string
}

const toWithDynamic: toWithDynamicArgs = (props, config = {}) => {
  const { type = "string", text, allowOneSpacePerWord = true } = props
  const { uppercase } = { ...globalConfigFormatDefault, ...config }
  let { allowedChars = "" } = props

  let regexStr
  if (type === "string") regexStr = "a-zA-Z"
  else if (type === "number") regexStr = "0-9"
  else if (type === "numbers-with-letters") regexStr = "a-zA-Z0-9"

  if (allowOneSpacePerWord) allowedChars += "s"

  const spaceReg = allowOneSpacePerWord ? "\\s" : ""
  const regex = new RegExp(`[^${regexStr}${spaceReg}${allowedChars}]`, "g")
  const regArray = allowedChars.split("").map((char) => new RegExp(`(\\${char}){2,}`, "g"))

  let newText = text.replace(regex, "")
  newText = removeRepeatChars(newText, regArray)
  if (uppercase) return newText.toLocaleUpperCase()
  return newText
}

const toLettersWithSpace = (text: string, config: Partial<GlobalConfigFormat> = {}) => {
  const { uppercase } = { ...globalConfigFormatDefault, ...config }
  const numericString = text.replace(/[^a-zA-Z\s]/g, "").replace(/\s{2,}/g, " ")
  if (uppercase) return numericString.toLocaleUpperCase()
  return numericString
}

const toLetters = (text: string, config: Partial<GlobalConfigFormat> = {}) => {
  const { uppercase } = { ...globalConfigFormatDefault, ...config }
  const numericString = text.replace(/[^a-zA-Z]/g, "")
  if (uppercase) return numericString.toLocaleUpperCase()
  return numericString
}

const toAlpha = (text: string, config: Partial<GlobalConfigFormat> = {}) => {
  const { uppercase } = { ...globalConfigFormatDefault, ...config }
  const numericString = text.replace(/[^a-zA-Z\s]|[\s]{2,}/g, "")
  if (uppercase) return numericString.toLocaleUpperCase()
  return numericString
}

const toAlphaNumeric = (text: string, config: Partial<GlobalConfigFormat> = {}) => {
  const { uppercase } = { ...globalConfigFormatDefault, ...config }
  const numericString = text.replace(/[^a-zA-Z0-9\s]|[\s]{2,}/g, "")
  if (uppercase) return numericString.toLocaleUpperCase()
  return numericString
}

const Format = {
  toCapitalize,
  formatPhoneNumber,
  unformatPhoneNumber,
  deepCopy,
  formatNumber,
  formatNumberV2,
  // 👉 Format Date
  formatDate,
  formatTime,
  timestampToDate,
  dateToStringShort,
  // 👉 Format Text
  getInitials,
  sleep,

  getValueAccordingMinMax,
  currency,
  toLettersWithSpaceDashes,
  toNumberWithDashes,
  toLettersWithSpace,
  toAlphaNumeric,
  toWithDynamic,
  toNumbers,
  toLetters,
  toAlpha,
  toPlate,
}

export default Format
