import type { IEquipmentFilters, LoadEquipmentsConfig, TEquipmentForm } from "./EquipmentType"

const form: TEquipmentForm = {
  id: 0,
  vim: "",
  status: "active",
  plate: "",
  make: "",
  model: "",
  year: "",
  description: "",
  serial_number: "",
  extra_data: "",
  equipment_id: "",
  current_zone_id: "",
  current_zone_code: "",
  class_id: "",
}

const filters: IEquipmentFilters = {
  search: "",
  order_by: "id",
  asc_or_desc: "desc",
  per_page: 22,
  page: 1,
}

const buildFilters = (newValues: Partial<IEquipmentFilters> = {}): IEquipmentFilters => ({
  ...filters,
  ...newValues,
})

// 👉 For Dispatch
const loadEquipmentsConfig: LoadEquipmentsConfig = {
  refresh: false,
  filters: {},
}
// 🖐️ End For Dispatch

export default {
  loadEquipmentsConfig,
  buildFilters,
  form,
  filters,
}
