import React from "react"
import clsx from "clsx"
import { IconType } from "react-icons"
import Tailwindcss from "@/utils/tailwind"
import { Required } from "./Required"
import { ErrorMessage } from "./ErrorMessage"

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  className?: string
  classesWrapper?: string
  fontSize?: string
  IconEnd?: IconType
  IconStart?: IconType
  ButtonStart?: React.ReactNode
  ButtonEnd?: React.ReactNode
  attrRef?: React.Ref<HTMLInputElement> | null
  inputSize?: "sm" | "default"
  label?: string | React.ReactNode
  classNameLabel?: string
  showRequired?: boolean
  error?: string
  textarea?: boolean
  someDescription?: React.ReactNode
  descriptionInput?: string
  preventDefaultEnter?: boolean
}

export const SuperInput = ({
  className,
  fontSize = "text-sm",
  classesWrapper = "",
  IconEnd,
  IconStart,
  ButtonStart,
  ButtonEnd,
  type = "text",
  attrRef,
  inputSize = "default",
  label,
  showRequired = false,
  id,
  error = "",
  classNameLabel = "",
  textarea,
  someDescription,
  descriptionInput,
  preventDefaultEnter = true,
  ...props
}: InputProps): JSX.Element => {
  const __input_size__ = {
    sm: "min-h-[24px] h-[24px] max-h-[24px]",
    default: "min-h-[34px] h-[34px] max-h-[24px]",
  }[inputSize]
  let inputBaseClass = Tailwindcss.mergeClasses(
    "block m-0 w-full overflow-hidden text-slate-800 font-normal rounded px-3 py-1 border border-gray-300 placeholder:text-gray-400 placeholder:font-normal focus:border-blue-400 sm:leading-6 outline-none placeholder:transition-all focus:placeholder:opacity-60 placeholder:text-xs bg-transparent dark:bg-transparent dark:border-slate-600 focus:placeholder:translate-x-1 dark:text-slate-400 disabled:bg-gray-100 disabled:dark:bg-slate-950 dark:disabled:text-gray-400 | read-only:bg-gray-100 read-only:dark:bg-slate-950 dark:read-only:text-gray-400 ",
    !textarea ? __input_size__ : "",
  )
  inputBaseClass = Tailwindcss.mergeClasses(inputBaseClass, className)
  inputBaseClass = Tailwindcss.mergeClasses(inputBaseClass, !!ButtonEnd ? "!rounded-e-none" : "")
  const iconBaseClass = "text-gray-400 absolute top-0 h-full !text-lg pointer-events-none"
  const dynamicPadding = `${!!IconStart ? "ps-7" : ""} ${!!IconEnd ? "pe-7" : ""}`
  const dynamicBorderInput = `${!!ButtonStart ? "rounded-l-none !border-l-0" : ""} ${!!ButtonEnd ? "rounded-r-none !border-r-0" : undefined}`
  const cssClassesParent = Tailwindcss.mergeClasses(`flex h-full`, !textarea ? __input_size__ : "")
  const cssClassesSemiParent = Tailwindcss.mergeClasses(`!relative w-full h-full`, !textarea ? __input_size__ : "")

  const dynamicAttrs: Record<string, any> = {}
  if (preventDefaultEnter) {
    dynamicAttrs.onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      e.which === 13 && e.preventDefault()
    }
  }
  return (
    <div className={clsx("flex flex-col items-start", classesWrapper)}>
      {!!label && (
        <label htmlFor={id} className={Tailwindcss.mergeClasses("text-xs", classNameLabel)}>
          {label} {showRequired && <Required />} {!!someDescription && <span className="text-slate-400 text-xs">{someDescription}</span>}
        </label>
      )}
      <div className="w-full">
        <div className={cssClassesParent}>
          {!!ButtonStart && ButtonStart}
          <div className={cssClassesSemiParent}>
            {!!IconStart && <IconStart className={clsx(iconBaseClass, "left-1.5")} />}
            {!textarea ? (
              <input
                ref={attrRef}
                type={type}
                id={id}
                className={clsx(inputBaseClass, fontSize, dynamicPadding, dynamicBorderInput)}
                {...dynamicAttrs}
                {...props}
              />
            ) : (
              // @ts-ignore
              <textarea id={id} className={clsx(inputBaseClass, fontSize, dynamicPadding, dynamicBorderInput)} {...props}></textarea>
            )}
            {!!IconEnd && <IconEnd className={clsx(iconBaseClass, "right-1.5")} />}
          </div>
          {!!ButtonEnd && ButtonEnd}
        </div>
      </div>
      {!!descriptionInput && <span className="block italic text-slate-400 dark:text-slate-500 text-xs">{descriptionInput}</span>}
      <ErrorMessage error={error} />
    </div>
  )
}
