import { AxiosResponse } from "axios"
import { debounce } from "lodash"
import { useState } from "react"

import type { ResponseEquipments, TEquipmentForm, ResponseEquipment, EquipmentPicture, TLoading, Equipment } from "../EquipmentType"
export { default as InitState } from "../EquipmentInitial.state"
import Normalize from "@/utils/normalize"
import axios from "@axios"

export const useEquipments = (filters: TableFilters = {}) => {
  const [pagination, setPagination] = useState<Partial<Pagination>>({})
  const [pictures, setPictures] = useState<EquipmentPicture[]>([])
  const [equipments, setEquipments] = useState<Equipment[]>([])
  const [loading, setLoading] = useState<TLoading>()

  const fetchAll = async (params: TableFilters = filters): Promise<AxiosResponse<ResponseEquipments>> => {
    try {
      setLoading("FETCHING EQUIPMENTS")
      const response = await axios.get<ResponseEquipments>(`/equips`, { params: Normalize.removePropertiesWithEmptyString(params) })
      const { status, data, pagination } = response.data
      if (status) {
        setPagination(pagination)
        setEquipments(data)
      }
      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(undefined)
    }
  }

  const debounceFetchAll = debounce(fetchAll, 350)

  const store = async (payload: Partial<TEquipmentForm>): Promise<AxiosResponse<ResponseEquipment>> => {
    return await axios.post<ResponseEquipment>(`/equips`, payload)
  }

  const update = async (equipmentId: TEquipmentForm["id"], payload: Omit<TEquipmentForm, "id">): Promise<AxiosResponse<ResponseEquipment>> => {
    return await axios.put<ResponseEquipment>(`/equips/${equipmentId}`, payload)
  }

  const destroy = async (equipmentId: Equipment["id"]): Promise<AxiosResponse<ResponseDelete>> => {
    try {
      const response = await axios.delete<ResponseDelete>(`/equips/${equipmentId}`)
      const { status, msg } = response.data
      if (!status) throw new Error(msg)
      return response
    } catch (error) {
      throw error
    } finally {
    }
  }

  // 👉 Pictures
  const fetchEquipmentsPictures = async (equipmentId: ModelID): Promise<EquipmentPicture[]> => {
    try {
      setLoading("FETCHING PICTURES")
      const { data: res } = await axios.get<TResponseBase<EquipmentPicture[]>>(`list-equip-files/${equipmentId}`)
      const { status, data } = res
      if (status) setPictures(data)
      return data || []
    } catch (error) {
      setPictures([])
      throw error
    } finally {
      setLoading(undefined)
    }
  }

  const saveEquipmentPicture = async (payload: FormData): Promise<EquipmentPicture[]> => {
    try {
      const response = await axios.post<TResponseBase<EquipmentPicture[]>>(`upload-pictures-equips`, payload)
      const { status, data } = response.data
      if (status) {
        setPictures(data)
        return data
      }
      throw new Error("Error saving equipment picture")
    } catch (error) {
      throw error
    }
  }

  const destroyEquipmentPicture = async (payload: any): Promise<AxiosResponse<TResponseBase<EquipmentPicture[]>>> => {
    try {
      const response = await axios.post<TResponseBase<EquipmentPicture[]>>(`remove-pictures-equip`, payload)
      const { status, data } = response.data
      if (status) setPictures(data)
      return response
    } catch (error) {
      throw error
    }
  }

  return {
    debounceFetchAll,
    setEquipments,
    equipments,
    setLoading,
    loading,
    fetchAll,
    store,
    destroy,
    update,
    pagination,
    // 👉 Picture
    fetchEquipmentsPictures,
    pictures,
    setPictures,
    saveEquipmentPicture,
    destroyEquipmentPicture,
  }
}
