import { FaAngleLeft, FaAngleRight } from "react-icons/fa6"

import clsx from "clsx"
import { useMemo } from "react"

interface Props {
  pages: number | undefined
  currentPage: number | undefined
  onPageChange: (page: number) => void
  className?: string
  classNameWrapper?: string
  disabled?: boolean
}

export const Pagination = ({ pages = 1, currentPage = 1, onPageChange, className, disabled = false, classNameWrapper = "" }: Props): JSX.Element | null => {
  const isPrevDisabled = currentPage === 1
  const isNextDisabled = currentPage === pages

  const pageNumbers = useMemo<(number | string)[]>(() => {
    const _pages = []
    for (let i = 1; i <= pages; i++) {
      if (i == 1 || i == 2 || i == pages || i == pages - 1 || Math.abs(i - currentPage) <= 1) {
        _pages.push(i)
      } else if ((i == 3 && currentPage != 1) || (i == pages - 2 && currentPage != pages)) {
        _pages.push("...")
      }
    }
    return _pages
  }, [currentPage, pages])

  const handleChangePageClick = (page: number) => {
    if (page === currentPage) return
    onPageChange(page)
  }

  if (!pages || !currentPage || pages == 1) return null

  return (
    <div className={clsx(classNameWrapper, "flex flex-nowrap justify-start h-full", disabled && "opacity-50 pointer-events-none cursor-not-allowed")}>
      <button
        className={clsx(
          "relative inline-flex items-center rounded-l-md px-1 py-1.5 border border-gray-300 dark:border-slate-700",
          isPrevDisabled ? "opacity-30" : "dark:hover:bg-slate-700 hover:bg-gray-50",
        )}
        onClick={() => onPageChange(currentPage - 1)}
        disabled={isPrevDisabled}
        type="button"
      >
        <FaAngleLeft className="text-xs" />
      </button>
      {pageNumbers.map((page, index) => (
        <div key={index} className="flex items-stretch">
          {typeof page === "number" ? (
            <button
              type="button"
              className={clsx(
                "relative z-10 inline-flex items-center px-2 py-1.5 text-xs font-semibold border-y h-full border-r !last-of-type:border-r-0 dark:border-slate-700",
                page === currentPage
                  ? "bg-blue-950 dark:bg-slate-700 text-white focus:z-20 border-blue-950"
                  : "text-blue-950 dark:text-slate-300 border-gray-300 hover:bg-gray-50 dark:hover:bg-slate-700",
                className,
              )}
              onClick={() => handleChangePageClick(page)}
            >
              {page}
            </button>
          ) : (
            <div className="border-y border-e dark:border-slate-700 px-2 cursor-default select-none">{page}</div>
          )}
        </div>
      ))}
      <button
        className={clsx(
          "relative inline-flex items-center rounded-r-md px-1 py-1.5 border border-l-0 border-gray-300  dark:border-slate-700",
          isNextDisabled ? "opacity-30" : "dark:hover:bg-slate-700 hover:bg-gray-50",
        )}
        onClick={() => onPageChange(currentPage + 1)}
        disabled={isNextDisabled}
        type="button"
      >
        <FaAngleRight className="text-xs" />
      </button>
    </div>
  )
}
