interface IHasChangedPayload<T> {
  prev: T
  current: T
  watchParams?: (keyof T)[]
}

export const hasChanged = <T>({ prev, current, watchParams = [] }: IHasChangedPayload<T>) => {
  if (!!watchParams.length) {
    return watchParams.some((param) => prev[param] !== current[param])
  }
  // @ts-ignore
  return Object.keys(prev).some((key) => prev[key] !== current[key])
}

export const renderVersion = () => {
  const version = `1.0.23`
  console.log(
    `%c🚀 %cVersion ${version}`,
    "color: white; background-color: #007bff; padding: 4px; border-radius: 4px 0 0 4px;",
    "color: #007bff; display: inline-block; width: 100px; background-color: white; padding: 4px; padding-left: 16px; padding-right: 16px; border-radius: 0 4px 4px 0; font-weight: bold",
  )
}

export const completeValuesWithEmptyString = (values: any): any => {
  const newValue: any = {}
  Object.keys(values).forEach((key) => {
    newValue[key] = values[key] ?? ""
  })
  return newValue
}

export const getChangedValues = <T>(obj1: T, obj2: T): Partial<T> => {
  const changedValues: Partial<T> = {}
  // @ts-ignore
  Object.keys(obj2).forEach((key) => {
    // @ts-ignore
    if (String(obj1[key] || "").trim() !== String(obj2[key] || "").trim()) {
      // @ts-ignore
      changedValues[key] = obj2[key]
    }
  })
  return changedValues
}

export const renderHTML = (html: string = "") => {
  return html.replace(/</g, "&lt;").replace(/>/g, "&gt;")
}
