import { createContext, useCallback, useContext, useEffect, useState } from "react"
import { format, isThisYear, isToday } from "date-fns"
import { cloneDeep, debounce } from "lodash"
// @ts-ignore
import io from "socket.io-client"
import { useNavigate } from "react-router-dom"

import type {
  ISocketContext,
  NumberNotifications,
  AvailableRoom,
  NormalizeAvailableRoom,
  DriverWithMessage,
  OnlineUsers,
  PayloadSyncSession,
  Room,
  Message,
  ResponseDestroyMessage,
  RequestPayloadHistoryMessage,
  TUpdateMessagesList,
  TLayout,
  TEmitEvents,
} from "@/context/types"
import SoundChat from "@/assets/mp3/soundChat.mp3"
import { AuthenticationContext, getUserFromLocalStorage } from "./AuthenticateContext"
import { useDrivers } from "@/pages/Drivers/hooks/useDrivers"
import type { TDriver } from "@/pages/Trips/TripType"
import { NAME_PAGES } from "@/router/routeInitial.state"
import { registerServiceWorker, unregisterServiceWorker } from "@/serviceWorker/serviceWorkerRegistration"
import httpSocketServer from "@/libs/axiosSocketServer"
import { TKeyReorderColumnsV2 } from "@/pages/DispatchPage/constants"

export const SocketContext = createContext({} as ISocketContext)

export enum ContactListStatuses {
  Button = "Button",
  List = "List",
}

const INIT_CONNECTION: boolean = false

export let onGetUsersConnected: TClosureCallback<OnlineUsers> = (callback: any) => (params: OnlineUsers) => callback(params)
export let onChat: TClosureCallback<Message> = (callback: any) => (params: Message) => callback(params)
export let onUpdateMessage: TClosureCallback<Message> = (callback: any) => (params: Message) => callback(params)
export let onRequestAvailableRooms: TClosureCallback<AvailableRoom[]> = (callback: any) => (params: AvailableRoom[]) => callback(params)
export let onDestroyMessage: TClosureCallback<ResponseDestroyMessage> = (callback: any) => (params: ResponseDestroyMessage) => callback(params)
export let onReadMessage: TClosureCallback<Message> = (callback: any) => (params: Message) => callback(params)
export let onSyncSession: TClosureCallback<Room> = (callback: any) => (params: Room) => callback(params)
export let onReceiveGlobalMessage: TClosureCallback<Message> = (callback: any) => (params: Message) => callback(params)
export let onHistoryMessages: TClosureCallback<Message[]> = (callback: any) => (params: Message[]) => callback(params)

const SocketContextProvider = ({ children }: { children: React.ReactNode }) => {
  const user: User = getUserFromLocalStorage() || {}
  const [socket, setSocket] = useState<any>()
  const { logout } = useContext(AuthenticationContext)!
  useEffect(() => {
    const connectSocket = () => {
      const auth: User = getUserFromLocalStorage()
      const PATH = import.meta.env.VITE_SOCKET_PATH
      const URI = import.meta.env.VITE_SOCKET_URI
      const payloadConnection: any = {
        reconnectionDelay: 5000,
        path: PATH,
        transports: ["websocket", "polling"],
        upgrade: true,
      }
      const socketInstance = io(`${URI}/company-${auth.company.id}`, payloadConnection)
      setSocket(socketInstance)
      registerServiceWorker({ socket: socketInstance })
      socketInstance?.on("connect", () => {
        console.info("Connected 🚀")
        setIsConnected(true)
        setNoConnectionToServerSocket(false)
        // @ts-ignore
      })
      socketInstance.on("isConnected", (data: string) => {
        console.info("Receive", data)
      })
      socketInstance?.on("number-unread-messages", (payload: NumberNotifications[]) => {
        if (payload.length) {
          setNotificationsCount(payload)
        }
        setNotificationsSynchronized(true)
      })
      socketInstance?.on("connect_error", () => {
        console.error("Error Connect Socket 🤦‍♂️")
        setNoConnectionToServerSocket(true)
      })
      socketInstance?.on("disconnect", () => {
        setIsConnected(false)
      })
    }
    connectSocket()
    return () => {
      socket?.off()
      socket?.disconnect()
      unregisterServiceWorker()
    }
  }, [])
  const [contactListStatus, setContactListStatus] = useState<ContactListStatuses>(ContactListStatuses.Button)
  const [contactsSelected, setContactsSelected] = useState<DriverWithMessage[]>([])
  const [notificationsCount, setNotificationsCount] = useState<NumberNotifications[]>([])
  const [availableRooms, setAvailableRooms] = useState<AvailableRoom[]>([])
  const { fetchAll: fetchDrivers, drivers, loading: loadingDrivers } = useDrivers({ per_page: 200 })
  const [driversWithMessages, setDriversWithMessages] = useState<DriverWithMessage[]>([])
  const [onlineUsers, setOnlineUsers] = useState<OnlineUsers["clientsDriver"]>([])
  const [joinedRoom, setJoinedRoom] = useState<Room | undefined>(undefined)
  const [isConnected, setIsConnected] = useState<boolean>(INIT_CONNECTION)
  const [chatsSynchronized, setChatsSynchronized] = useState<boolean>(false)
  const [notificationsSynchronized, setNotificationsSynchronized] = useState<boolean>(false)
  const [noConnectionToServerSocket, setNoConnectionToServerSocket] = useState<boolean>(false)
  const navigation = useNavigate()
  const [audio] = useState(() => {
    const _audio = new Audio(SoundChat)
    return _audio
  })

  const formatDate = (dateTime: string = "") => {
    const date = new Date(dateTime)
    if (isToday(date)) {
      return format(date, "hh:mm a")
    } else if (isThisYear(date)) {
      return format(date, "dd MMM hh:mm a")
    }
    return format(date, "dd MMM yy hh:mm a")
  }

  useEffect(() => {
    if (socket?.connected && !!drivers.length) {
      establishConnectionWithSocket()
    }
  }, [socket, drivers])

  const establishConnectionWithSocket = () => {
    const auth: User = getUserFromLocalStorage()
    if (auth && auth.role.name === "user_admin") {
      const username = String(auth?.name).toLocaleUpperCase()
      socket?.emit("setUser", { username, type: "dispatch" })
      // socket?.emit("check_user_online")
      socket?.emit("request-available-rooms", { username })
      socket?.emit("number-unread-messages", { username })
      if (joinedRoom) {
        joinChat(joinedRoom)
      }
    }
  }

  onChat = useCallback(
    (newMessage: Message) => {
      if (!newMessage.where && String(user.name).toLocaleUpperCase() === newMessage.user) {
        const index = driversWithMessages.findIndex((c) => c.roomId === newMessage.session_id)
        if (index === -1) return
        updateMessagesList({ message: newMessage, driverIndex: index })
      }
    },
    [driversWithMessages],
  )

  useEffect(() => {
    if (user.role.name === "user_admin") {
      if (!drivers.length) fetchDrivers()
    }
  }, [socket, isConnected])

  useEffect(() => {
    const run = debounce(() => {
      const values: NormalizeAvailableRoom[] = availableRooms.map((item) => {
        const { lastMessage, ...room } = item
        return {
          ...item,
          lastMessage: lastMessage,
          roomId: room.id,
          room: room,
          unreadCount: notificationsCount.find((c) => c._id === room.id)?.count ?? 0,
        }
      })
      const normalize: DriverWithMessage[] = drivers.map((driver) => {
        const value = values.find((c) => String(c.user2_id).toLocaleUpperCase() === String(driver.name).toLocaleUpperCase())
        let lastMessage
        if (value?.lastMessage) {
          lastMessage = setNormalizeMessage(value?.lastMessage)
        }
        return {
          ...driver,
          name: String(driver.name).toLocaleUpperCase(),
          lastMessage,
          roomId: value?.roomId,
          room: value?.room,
          unreadCount: value?.unreadCount,
        }
      })
      setDriversWithMessages(normalize)
      setChatsSynchronized(true)
    }, 500)
    if (socket?.connected && drivers.length && !chatsSynchronized && notificationsSynchronized) run()
    return () => run.cancel()
  }, [socket, notificationsCount, availableRooms, drivers, driversWithMessages, chatsSynchronized, notificationsSynchronized])

  const syncSession = (payload: PayloadSyncSession) => {
    socket?.emit("sync-session", payload)
  }

  const setNormalizeMessage = (message: Message) => ({
    ...message,
    dateSendAtString: formatDate(message?.send_at?.date),
  })

  const increaseNotificationCount = (_id: NumberNotifications["_id"], count: number) => {
    const index = notificationsCount.findIndex((c) => c._id === _id)
    if (count > 0) {
      audio.currentTime = 0
      audio.play()
    }
    if (index != -1) {
      setNotificationsCount((prev) => {
        const newNotifications = [...prev]
        newNotifications[index] = {
          ...prev[index],
          count: prev[index].count + count,
        }
        return newNotifications
      })
    } else {
      setNotificationsCount((prev) => {
        const newNotifications = [...prev]
        newNotifications.push({ _id, count })
        return newNotifications
      })
    }
  }

  const updateMessagesList = ({ message, driverIndex, action = "INSERT", isNotification = false }: TUpdateMessagesList) => {
    setDriversWithMessages((prev) => {
      const newDriversWithMessages = cloneDeep(prev)
      const prevMessages = newDriversWithMessages[driverIndex]["messages"] ?? []
      if (action === "INSERT") {
        const currentMessage: Message = setNormalizeMessage(message as Message)
        newDriversWithMessages[driverIndex].lastMessage = currentMessage
        if (isNotification) {
          if (prevMessages.length) {
            newDriversWithMessages[driverIndex].messages = [...prevMessages, currentMessage]
          }
        } else {
          newDriversWithMessages[driverIndex].messages = [...prevMessages, currentMessage]
        }
        if (joinedRoom?.id !== currentMessage.session_id) {
          newDriversWithMessages[driverIndex].unreadCount = (newDriversWithMessages[driverIndex].unreadCount ?? 0) + 1
          increaseNotificationCount(currentMessage.session_id, 1)
        }
      } else if (action === "UPDATE") {
        const currentMessage: Message = setNormalizeMessage(message as Message)
        const index = prevMessages.findIndex((_message) => _message.id === currentMessage.id)
        prevMessages.splice(index, 1, currentMessage)
        if (newDriversWithMessages[driverIndex].lastMessage?.id === currentMessage.id) {
          newDriversWithMessages[driverIndex].lastMessage = currentMessage
        }
        newDriversWithMessages[driverIndex].messages = prevMessages
      } else {
        const index = prevMessages.findIndex((_message) => _message.id === message)
        const lastIndex = prevMessages.length - 1
        if (index === lastIndex) {
          const newLastMessage = prevMessages[lastIndex - 1]
          newDriversWithMessages[driverIndex].lastMessage = newLastMessage ?? undefined
        }
        prevMessages.splice(index, 1)
        newDriversWithMessages[driverIndex].messages = prevMessages
      }
      return newDriversWithMessages
    })
  }

  const joinChat = (data: Room) => {
    socket?.emit("join", {
      id: data.id,
      user1_id: data.user2_id,
      user2_id: data.user1_id,
      trip: data.trip,
    })
  }

  const getHistoryMessages = (data: RequestPayloadHistoryMessage) => {
    socket?.emit("history-messages", data)
  }

  onUpdateMessage = useCallback(
    (messageUpdated: Message) => {
      const index = driversWithMessages.findIndex((c) => c.name === messageUpdated.forUser)
      updateMessagesList({ message: messageUpdated, driverIndex: index, action: "UPDATE" })
    },
    [driversWithMessages],
  )

  onDestroyMessage = useCallback(
    (payload: ResponseDestroyMessage) => {
      const index = driversWithMessages.findIndex((c) => c.roomId === payload.sessionId)
      if (index === -1) return
      updateMessagesList({ message: payload.id, driverIndex: index, action: "DESTROY" })
    },
    [driversWithMessages],
  )

  onSyncSession = useCallback(
    (payload: Room) => {
      const index = driversWithMessages.findIndex((c) => c.name === payload.user2_id)
      if (index !== -1 && !driversWithMessages[index].roomId) {
        const newList = cloneDeep(driversWithMessages)
        newList[index].roomId = payload.id
        setDriversWithMessages(newList)
      }
      setJoinedRoom(payload)
    },
    [driversWithMessages],
  )

  onReceiveGlobalMessage = useCallback(
    (newMessage: Message) => {
      const index = driversWithMessages.findIndex((c) => c.roomId === newMessage.session_id)
      if (index !== -1) {
        updateMessagesList({ message: newMessage, driverIndex: index, isNotification: true })
      } else {
        const currentDriverIndex = driversWithMessages.findIndex((c) => c.name === newMessage.user)
        if (currentDriverIndex !== -1) {
          setDriversWithMessages((prev) => {
            const newState = [...prev]
            newState[currentDriverIndex].lastMessage = setNormalizeMessage(newMessage)
            newState[currentDriverIndex].unreadCount = (newState[currentDriverIndex].unreadCount ?? 0) + 1
            newState[currentDriverIndex].messages = [setNormalizeMessage(newMessage)]
            newState[currentDriverIndex].roomId = newMessage.session_id
            newState[currentDriverIndex].room = {
              id: newMessage.session_id,
              user1_id: newMessage.forUser,
              user2_id: newMessage.user,
              trip: undefined,
              createdAt: newMessage.createdAt,
              updatedAt: newMessage.updatedAt,
            }
            return newState
          })
          increaseNotificationCount(newMessage.session_id, 1)
        }
      }
      if (newMessage.session_id === joinedRoom?.id) {
        markAsRead(joinedRoom.id, joinedRoom.user1_id, joinedRoom.user2_id)
      }
    },
    [driversWithMessages, joinedRoom],
  )

  useEffect(() => {
    onRequestAvailableRooms = (payload: AvailableRoom[]) => {
      if (payload.length) {
        setAvailableRooms(payload)
      }
    }
    onReadMessage = (payload: Message) => {
      if (payload.user === String(user.name).toLocaleUpperCase()) {
        setDriversWithMessages((prev) => {
          const index = prev.findIndex((c) => c.roomId === payload.session_id)
          if (index !== -1) {
            const newList = cloneDeep(prev)
            const newMessageIndex = (newList[index].messages || [])?.findIndex((c) => c.id === payload.id)
            if (newMessageIndex) {
              newList[index]["messages"]![newMessageIndex] = setNormalizeMessage(payload)
              return newList
            }
            return newList
          }
          return prev
        })
      }
    }
    onGetUsersConnected = (payload: OnlineUsers) => {
      const { clientsDriver } = payload
      setOnlineUsers(clientsDriver)
    }
    onHistoryMessages = (payload: Message[]) => {
      const messages = payload.map(setNormalizeMessage).reverse()
      if (!joinedRoom) return
      setDriversWithMessages((prev) => {
        const index = prev.findIndex((c) => c.name === joinedRoom?.user2_id)
        if (index !== -1) {
          const newDrivers = [...prev]
          newDrivers[index] = { ...newDrivers[index], messages }
          return newDrivers
        }
        return prev
      })
    }
    return () => {}
  }, [driversWithMessages, joinedRoom, socket])

  const markAsRead = (sessionId: number, username: string, toUser: string) => {
    socket?.emit("read_message", { sessionId, username, toUser })
  }

  useEffect(() => {
    if (joinedRoom) {
      joinChat(joinedRoom)
      const index = driversWithMessages.findIndex((c) => c.roomId === joinedRoom.id)
      if (index !== -1 && !!driversWithMessages[index].unreadCount) {
        markAsRead(joinedRoom.id, joinedRoom.user1_id, joinedRoom.user2_id)
        increaseNotificationCount(joinedRoom.id, driversWithMessages[index].unreadCount! * -1)
        setDriversWithMessages((prev) => {
          const newState = [...prev]
          newState[index].unreadCount = 0
          return newState
        })
      }
      if (!driversWithMessages[index]?.room) {
        setDriversWithMessages((prev) => {
          const newState = [...prev]
          if (newState[index]) newState[index].room = joinedRoom
          return newState
        })
      }
    }
  }, [joinedRoom])

  const selectNotification = (driver: DriverWithMessage) => {
    selectContact(driver)
  }

  const selectContact = (driver: DriverWithMessage) => {
    syncSession({
      user: String(user.name).toLocaleUpperCase(),
      driver: driver.name,
    })
    setContactsSelected((prev) => {
      const newList = [...prev]
      const index = prev.findIndex((c) => c.id === driver.id)
      if (index === -1) newList.push(driver)
      return newList
    })
  }

  const unselectContact = (driver: DriverWithMessage) => {
    setContactsSelected((prev) => {
      const index = prev.findIndex((c) => c.id === driver.id)
      if (index !== -1) {
        const newList = [...prev]
        newList.splice(index, 1)
        return newList
      }
      return prev
    })
  }

  const selectDriverFromDrivers = (driver: TDriver) => {
    const index = driversWithMessages.findIndex((_driver) => _driver.id === driver.id)
    if (index !== -1) {
      selectContact(driversWithMessages[index])
    }
  }

  // Listen a columns sizes
  const handleTableChange = (key: string, payload: Record<string, string>, page = NAME_PAGES.DISPATCH, immediate: boolean = false) => {
    if (!immediate) {
      bulkUpdateColumnsQueue.cancel()
      bulkUpdateColumnsQueue({ type: "tableColumnsSizes", payload, key, page })
    } else {
      emitBulkUpdateWithoutDelay({ type: "tableColumns", payload, key, page })
    }
  }

  const handleTableChangeV2 = (key: TKeyReorderColumnsV2, payload: Record<string, string>, page = NAME_PAGES.DISPATCH, immediate: boolean = false) => {
    if (!immediate) {
      bulkUpdateColumnsQueue.cancel()
      bulkUpdateColumnsQueue({ type: "tableColumnsSizes", payload, key, page })
    } else {
      emitBulkUpdateWithoutDelay({ type: "tableColumns", payload, key, page })
    }
  }

  const handleTableSizeChange = (key: string, payload: string, page = NAME_PAGES.DISPATCH) => {
    bulkUpdateColumnsQueue.cancel()
    bulkUpdateColumnsQueue({ type: "tableSizes", payload, key, page })
  }

  const handleSortColumnTableChange = (key: string, payload: TableStructure[], page = NAME_PAGES.DISPATCH) => {
    bulkUpdateColumnsQueue.cancel()
    bulkUpdateColumnsQueue({ type: "tableColumns", payload, key, page })
  }

  const bulkUpdateColumnsQueue = debounce((data: TLayout) => {
    const auth: User = getUserFromLocalStorage()
    const payload = {
      companyId: auth.company_id,
      userId: auth.id,
      ...data,
    }
    httpSocketServer.post("user-configs/user-config-update-columns", payload)
  }, 500)

  const storeConfig = async (payload: any, uri: any = "user-config-update-columns") => {
    try {
      await httpSocketServer.post(`user-configs/${uri}`, payload)
    } catch (error) {}
  }

  const emitBulkUpdateWithoutDelay = (data: any, uri?: any) => {
    try {
      const auth: User = getUserFromLocalStorage()
      const payload = {
        companyId: auth.company_id,
        userId: auth.id,
        ...data,
      }
      storeConfig(payload, uri)
    } catch (error) {}
  }

  const emitBulkUpdateColumnsQueue = debounce((data: any) => {
    try {
      const auth: User = getUserFromLocalStorage()
      const payload = {
        companyId: auth.company_id,
        userId: auth.id,
        ...data,
      }
      storeConfig(payload)
    } catch (error) {}
  }, 300)

  const emitEvent = useCallback(
    (event: TEmitEvents, data: unknown) => {
      if (socket?.connected) {
        socket.emit(event, data)
      }
    },
    [socket],
  )

  useEffect(() => {
    if (!user) {
      logout()
      navigation("/sign-in")
    }
  }, [user])

  const values: ISocketContext = {
    socket,
    drivers,
    selectNotification,
    setContactListStatus,
    contactListStatus,
    setContactsSelected,
    contactsSelected,
    selectContact,
    setNotificationsCount,
    notificationsCount,
    setAvailableRooms,
    availableRooms,
    setDriversWithMessages,
    driversWithMessages,
    onlineUsers,
    unselectContact,
    user,
    syncSession,
    joinedRoom,
    joinChat,
    getHistoryMessages,
    setJoinedRoom,
    formatDate,
    loadingDrivers,
    setNormalizeMessage,
    selectDriverFromDrivers,
    isConnected,
    // 👉 update user config
    bulkUpdateColumnsQueue,
    handleTableChange,
    handleSortColumnTableChange,
    handleTableSizeChange,
    emitBulkUpdateColumnsQueue,
    noConnectionToServerSocket,
    setNoConnectionToServerSocket,
    emitBulkUpdateWithoutDelay,
    emitEvent,
    handleTableChangeV2,
  }

  return <SocketContext.Provider value={values}>{children}</SocketContext.Provider>
}

export const useSocket = () => {
  const context = useContext(SocketContext)
  if (!context) {
    throw new Error("useSocket must be used within a SocketContextProvider")
  }
  return context
}

export default SocketContextProvider
