import { cloneDeep } from "lodash"

import type { TDispatchActionsTypes } from "@pages/DispatchPage/DispatchType"
import DispatchAdapter from "@pages/DispatchPage/dispatch.adapter"

export const dispatchActionsTypes: TDispatchActionsTypes = {
  SET_FREIGHTS(state, { type, refresh, newLoads }) {
    const newFreights = cloneDeep(state.freights)
    if (type === "Pickup") {
      newFreights.pickups = newLoads!.map(DispatchAdapter.LoadsToTable)
      if (refresh) newFreights.deliveries = []
    } else {
      newFreights.deliveries = newLoads!.map(DispatchAdapter.LoadsToTable)
      if (refresh) newFreights.pickups = []
    }
    return {
      ...state,
      freights: newFreights,
    }
  },
  TOGGLE_ADD_TO_FREIGHTS(state, payload) {
    const { type, freightId, action = "REMOVE", newItem } = payload
    const { freights, terminalSelectedId, filtersLoads } = state
    if (type === "Pickup") {
      if (terminalSelectedId != newItem?.pickup_terminal_id) {
        return { ...state }
      }
    } else {
      if (typeof newItem?.delivery_terminal === "number") {
        if (terminalSelectedId != newItem?.delivery_terminal) {
          return { ...state }
        }
      } else {
        if (terminalSelectedId != newItem?.delivery_terminal_id) {
          return { ...state }
        }
      }
    }
    const newFreights = cloneDeep(freights)
    if (action === "REMOVE") {
      if (type === "Pickup") {
        newFreights.pickups = newFreights.pickups.filter((c) => freightId != c.id)
      } else {
        newFreights.deliveries = newFreights.deliveries.filter((c) => freightId != c.id)
      }
    } else {
      if (newItem) {
        if (type === "Pickup") {
          if (!freights.pickups.length && filtersLoads.type != "Pickup") {
            return { ...state }
          }
          newFreights.pickups = [...newFreights.pickups, newItem]
        } else {
          if (!freights.deliveries.length && filtersLoads.type != "Delivery") {
            return { ...state }
          }
          newFreights.deliveries = [...newFreights.deliveries, newItem]
        }
      }
    }
    return {
      ...state,
      freights: newFreights,
    }
  },
  UPDATE_TO_FREIGHT(state, payload) {
    const { type, newItem } = payload
    const { freights, terminalSelectedId, filtersLoads } = state
    if (type === "Pickup") {
      if (terminalSelectedId != newItem?.pickup_terminal_id) {
        return { ...state }
      }
    } else {
      if (typeof newItem?.delivery_terminal === "number") {
        if (terminalSelectedId != newItem?.delivery_terminal) {
          return { ...state }
        }
      } else {
        if (terminalSelectedId != newItem?.delivery_terminal_id) {
          return { ...state }
        }
      }
    }
    const newFreights = cloneDeep(freights)
    if (type === "Pickup") {
      if (!freights.pickups.length && filtersLoads.type != "Pickup") {
        return { ...state }
      }
      const index = newFreights.pickups.findIndex((item) => item.id == newItem.id)
      if (index != -1) newFreights.pickups.splice(index, 1, newItem)
    } else {
      if (!freights.deliveries.length && filtersLoads.type != "Delivery") {
        return { ...state }
      }
      const index = newFreights.pickups.findIndex((item) => item.id == newItem.id)
      if (index != -1) newFreights.deliveries.splice(index, 1, newItem)
    }
    return {
      ...state,
      freights: newFreights,
    }
  },
  SET_FILTER_LOADS(state, newFilters) {
    return {
      ...state,
      filtersLoads: {
        ...state.filtersLoads,
        ...newFilters,
      },
    }
  },
  SET_PREV_FILTER_LOADS(state, newFilters) {
    return {
      ...state,
      prevFiltersLoads: {
        ...state.prevFiltersLoads,
        ...newFilters,
      },
    }
  },
  UPDATE_TRIPS(state, newTrip) {
    const newListTrips = cloneDeep(state.trips).map((trip) => (trip.id == newTrip.id ? newTrip : trip))
    return { ...state, trips: newListTrips }
  },
  UPDATE_TRIP_SELECTED(state, newTrip) {
    const { tripSelected } = state
    if (!tripSelected || tripSelected.id != newTrip.id) return { ...state }
    return { ...state, tripSelected: newTrip }
  },
  UPDATE_FREIGHT_OF_TRIP_SELECTED(state, { tripId, freight }) {
    const { tripSelected } = state
    if (!tripSelected || tripSelected.id != tripId) return { ...state }
    const newTripSelected = cloneDeep(tripSelected)
    const index = newTripSelected.freights.findIndex((f) => f.id == freight.id && f.tx_type == freight.tx_type)
    if (index != -1) newTripSelected.freights.splice(index, 1, freight)
    return { ...state, tripSelected: newTripSelected }
  },
  SET_TRIP_SELECTED(state, newTrip) {
    const { tripSelected, currentTripSelectedId } = state
    if (newTrip?.id == tripSelected?.id || newTrip?.id == currentTripSelectedId) {
      return { ...state, tripSelected: newTrip }
    }
    return { ...state }
  },
  CURRENT_TRIP_SELECTED_ID(state, payload) {
    return { ...state, currentTripSelectedId: payload }
  },
  SET_TRIPS(state, newTripList) {
    return { ...state, trips: newTripList }
  },
  SET_TRIP_SELECTED_FOR_ME(state, newTrip) {
    return { ...state, tripSelected: newTrip }
  },
  SET_TERMINAL_SELECTED_ID(state, terminalSelectedId) {
    return { ...state, terminalSelectedId }
  },
  TOGGLE_ADD_TRIP_TO_TRIPS(state, payload) {
    const { TYPE_ACTION, data } = payload
    const { trips, terminalSelectedId } = state
    if (terminalSelectedId != data.terminal_id) return { ...state }
    const newTrips = cloneDeep(trips)
    if (TYPE_ACTION === "ADD") {
      newTrips.unshift(data)
    } else if (TYPE_ACTION === "REMOVE") {
      const index = newTrips.findIndex((c) => c.id == data.id)
      if (index !== -1) {
        newTrips.splice(index, 1)
      }
    }
    return { ...state, trips: newTrips }
  },
  SET_FILTER_TRIPS(state, newFilters) {
    return {
      ...state,
      filtersTrips: { ...state.filtersTrips, ...newFilters },
    }
  },
  SET_PREV_FILTER_TRIPS(state, newFilters) {
    return {
      ...state,
      prevFiltersTrips: { ...state.prevFiltersTrips, ...newFilters },
    }
  },
  SET_FILTER_DOORS(state, newFilters) {
    return {
      ...state,
      filtersDoors: { ...state.filtersDoors, ...newFilters },
    }
  },
  SET_PREV_FILTER_DOORS(state, newFilters) {
    return {
      ...state,
      prevFiltersDoors: { ...state.prevFiltersDoors, ...newFilters },
    }
  },
  SET_DOORS(state, doors) {
    return { ...state, doors }
  },
  ADD_DOOR(state, door) {
    const { terminalSelectedId, doors } = state
    if (terminalSelectedId != door?.terminal?.id) return { ...state }
    if (!doors.length) return { ...state }
    const newDoors = cloneDeep(state.doors)
    newDoors.unshift(door)
    return { ...state, doors: newDoors }
  },
  UPDATE_DOOR(state, door) {
    const { doors } = state
    return {
      ...state,
      doors: doors.map((currDoor) => (currDoor.id == door.id ? door : currDoor)),
    }
  },
  REMOVE_DOOR(state, door) {
    const { terminalSelectedId, doors } = state
    if (terminalSelectedId != door?.terminal?.id) return { ...state }
    return {
      ...state,
      doors: doors.filter((currDoor) => currDoor.id != door.id),
    }
  },
  SET_TERMINAL_FILTERS(state, newTerminalFilter) {
    return {
      ...state,
      filtersTrips: { ...state.filtersTrips, ...newTerminalFilter },
      filtersDoors: { ...state.filtersDoors, ...newTerminalFilter },
      filtersLoads: { ...state.filtersLoads, ...newTerminalFilter },
      terminalSelectedId: Number(newTerminalFilter.terminal_id),
      tripSelected: undefined,
    }
  },
  // 👉 Equipments
  SET_EQUIPMENTS(state, equipments) {
    return { ...state, equipments }
  },
  UPDATE_EQUIPMENT(state, { equipment, action }) {
    const { equipments } = state
    if (action === "INSERT") {
      const newEquipments = cloneDeep(equipments)
      newEquipments.unshift(equipment)
      return { ...state, equipments: newEquipments }
    } else if (action === "UPDATE") {
      return {
        ...state,
        equipments: equipments.map((currEquipment) => (currEquipment.id == equipment.id ? equipment : currEquipment)),
      }
    } else if (action === "DELETE") {
      return {
        ...state,
        equipments: equipments.filter((currEquipment) => currEquipment.id != equipment.id),
      }
    }
    console.warn("Action not found")
    return { ...state }
  },
  SET_FILTER_EQUIPMENTS(state, newFilters) {
    return {
      ...state,
      filtersEquipments: { ...state.filtersEquipments, ...newFilters },
    }
  },
  SET_PREV_FILTER_EQUIPMENTS(state, newFilters) {
    return {
      ...state,
      prevFiltersEquipments: { ...state.prevFiltersEquipments, ...newFilters },
    }
  },
  // 🖐️ End Equipments
  TOGGLE_LOADING(state, { key, value }) {
    const loadings = cloneDeep(state.loadings)
    if (value) {
      loadings[key] = value
    } else {
      delete loadings[key]
    }
    return { ...state, loadings }
  },
}
