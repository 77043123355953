import { TbChevronDown } from "react-icons/tb"
import clsx from "clsx"

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
  className?: string
  children: React.ReactNode
  showIcon?: boolean
}

export const Select = ({ children, className, showIcon = true, ...props }: Props) => {
  return (
    <div className="relative w-full">
      <select
        className={clsx(
          "cursor-pointer block appearance-none text-sm w-full h-[34px] bg-white border border-gray-300  focus:border-blue-400 px-3 py-1.5 pr-8 rounded focus:outline-none dark:bg-slate-900 dark:text-slate-300 dark:border-slate-600",
          className,
        )}
        {...props}
      >
        {children}
      </select>
      {showIcon && (
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-400">
          <TbChevronDown size={12} />
        </div>
      )}
    </div>
  )
}
