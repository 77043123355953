import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useReducer, useRef, useState } from "react"
import type { TPageDB } from "@/router/type"
import type { IResponseUserConfig, ISocketContext } from "./types"
import { SocketContext } from "./SocketContext"
import { TTableLoads, TTableFormLoad } from "@/pages/Loads/Load.columns"
import * as initialStateLoadColumns from "@/pages/Loads/Load.columns"
import { mergeColumns } from "@/pages/DispatchPage/utils"
import { getUserFromLocalStorage } from "./AuthenticateContext"
import httpSocketServer from "@/libs/axiosSocketServer"
import { columnReducer } from "@/store/reducers"
import InitStateColumns from "@/store/initState/columns"
import type { TColumnActionsTypes, TColumnState } from "@/types/Column"
import { TAction } from "@/store/AppTypes"

interface IColumnsContext {
  loadColumns(page: TPageDB): void
  columnsLoads: Column[]
  columnsLoadsDetailsForm: Column[]
  setColumnsLoads: Dispatch<SetStateAction<Column[]>>
  setColumnsLoadsDetailsForm: Dispatch<SetStateAction<Column[]>>
  handleSortColumnTableChange: ISocketContext["handleSortColumnTableChange"]
  tableBlockSize: Record<any, any>
  setTableBlockSize: Dispatch<SetStateAction<Record<any, any>>>
  storeColumns: TColumnState
  dispatchColumns: Dispatch<TAction<TColumnActionsTypes>>
}

interface OnGetStoredColumns {
  (payload: any): void
}

export const ColumnContext = createContext<IColumnsContext>({} as IColumnsContext)

const ColumnContextProvider = ({ children }: { children: ReactNode }) => {
  const auth: User = getUserFromLocalStorage()
  const currentPage = useRef<TPageDB>()
  const synchronizedPages = useRef<TPageDB[]>([])
  const [tableBlockSize, setTableBlockSize] = useState<Record<any, any>>({})
  const [columnsLoads, setColumnsLoads] = useState<Column[]>([])
  const [columnsLoadsDetailsForm, setColumnsLoadsDetailsForm] = useState<Column[]>([])
  const { socket, handleSortColumnTableChange, isConnected } = useContext(SocketContext)
  const [storeColumns, dispatchColumns] = useReducer(columnReducer, InitStateColumns)
  const waitingSocketConnectionTimeout = useRef<NodeJS.Timeout>()

  const syncPage = () => {
    if (!currentPage.current) return
    synchronizedPages.current.push(currentPage.current)
  }

  const upColumns = (page: TPageDB, storedColumns: Column[] = []) => {
    if (page === "LOADS") {
      if (!!columnsLoads.length) return
      const newColumns = mergeColumns(initialStateLoadColumns.COLUMNS_LOADS, storedColumns)
      setColumnsLoads(newColumns)
    } else if (page === "FORM_LOAD") {
      if (!!columnsLoadsDetailsForm.length) return
      const newColumns = mergeColumns(initialStateLoadColumns.COLUMNS_DETAILS, storedColumns)
      setColumnsLoadsDetailsForm(newColumns)
    }
  }

  const fetchStoredColumns = async (params: any) => {
    try {
      const { data } = await httpSocketServer.get("/user-configs", { params })
      onGetStoredColumns(data)
    } catch (error) {
      onGetStoredColumns([])
    }
  }

  const onGetStoredColumns: OnGetStoredColumns = (originPayload: any = {}) => {
    if (waitingSocketConnectionTimeout.current) clearTimeout(waitingSocketConnectionTimeout.current)
    syncPage()
    if (currentPage.current === "FORM_LOAD") {
      const payload: IResponseUserConfig<TTableFormLoad> = (originPayload as IResponseUserConfig<TTableFormLoad>) || {}
      upColumns("FORM_LOAD", payload?.tableColumns?.DETAILS || [])
      if (payload.tableSizes?.DETAILS) setTableBlockSize((prev) => ({ ...prev, FORM_LOAD: payload.tableSizes || {} }))
    } else if (currentPage.current === "LOADS") {
      const payload: IResponseUserConfig<TTableLoads> = (originPayload as IResponseUserConfig<TTableLoads>) || {}
      upColumns("LOADS", payload?.tableColumns?.MAIN || [])
      if (payload.tableSizes?.MAIN) setTableBlockSize((prev) => ({ ...prev, LOADS: payload.tableSizes || {} }))
    }
    currentPage.current = undefined
  }

  const loadColumns = useCallback(
    (page: TPageDB) => {
      try {
        currentPage.current = page
        if (!currentPage.current || synchronizedPages.current.includes(currentPage.current)) return
        fetchStoredColumns({ companyId: auth.company_id, userId: auth.id, page })
      } catch (error) {}
    },
    [socket, isConnected],
  )

  const share = {
    loadColumns,
    columnsLoads,
    setColumnsLoads,
    columnsLoadsDetailsForm,
    setColumnsLoadsDetailsForm,
    handleSortColumnTableChange,
    setTableBlockSize,
    tableBlockSize,
    socket,
    dispatchColumns,
    storeColumns,
  }

  return <ColumnContext.Provider value={share}>{children}</ColumnContext.Provider>
}

export const useColumnsContext = () => {
  const ctx = useContext(ColumnContext)
  if (!ctx) throw new Error("useColumnsContext must be used within a ColumnContextProvider")
  return ctx
}

export default ColumnContextProvider
