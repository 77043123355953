import { useContext, useEffect, useState } from "react"
import { useFormik, FormikHelpers } from "formik"
import { useLocation, useNavigate } from "react-router-dom"
import { AxiosError } from "axios"
import { useIntl } from "react-intl"

import { Input, ErrorMessage, Button, Divider } from "@core/index"
import { ButtonGoogle } from "../components"
import type { ResponseLogin, FormLogin, LoginGoogleResponse, ResponseLoginGoogle } from "@/pages/Auth/AuthTypes"
import axiosInstance from "@axios"
import { LoginValidationSchema } from "../AuthValidation.schema"
import InitState from "../AuthInitial.state"
import { AuthenticationContext } from "@/context/AuthenticateContext"
import useDocumentTitle from "@/hooks/useDocumentTitle"

export const LoginPage = (): JSX.Element => {
  const { formatMessage: $t } = useIntl()
  const location = useLocation()
  const navigate = useNavigate()
  const { login, isLoggedIn, getAvailableMenus } = useContext(AuthenticationContext)!
  const [error, setError] = useState<string>("")

  useDocumentTitle($t({ id: "Sign In" }))

  useEffect(() => {
    const handler = async () => {
      try {
        const params = new URL((window as any).document.location).searchParams
        if (params.has("code") && params.get("gstatus") === "true") {
          const { data } = await axiosInstance.post<ResponseLoginGoogle>(`/auth/google/redirect`, { code: params.get("code") })
          if (data.msg) return setError(data.msg)
          login({
            token: data.api_token,
            user: data.user,
          })
        } else if (params.get("gstatus") === "false") {
          setError(params.get("msg") || "Error trying to log in with Google")
        }
      } catch (error) {}
    }
    handler()
  }, [location])

  const onSubmit = async (values: FormLogin, actions: FormikHelpers<FormLogin>) => {
    actions.setSubmitting(true)
    setError("")
    try {
      const { data } = await axiosInstance.post<ResponseLogin>("/login", values)
      login(data)
    } catch (error) {
      if (error instanceof AxiosError) {
        setError(error.response?.data?.error)
      }
    } finally {
      actions.setSubmitting(false)
    }
  }

  const { values, handleChange, handleSubmit, errors, isSubmitting } = useFormik<FormLogin>({
    initialValues: InitState.FormLogin,
    validationSchema: LoginValidationSchema,
    onSubmit,
    validateOnChange: false,
  })

  useEffect(() => {
    if (isLoggedIn) {
      const [firstPage] = getAvailableMenus()
      if (firstPage) {
        navigate(firstPage)
      }
    }
  }, [isLoggedIn])

  const [loadingLoginGoogle, setLoadingLoginGoogle] = useState<boolean>(false)
  const handleLoginGoogleClick = async () => {
    try {
      setLoadingLoginGoogle(true)
      const { data } = await axiosInstance.post<LoginGoogleResponse>(`google_auth`, { from: "t4web" })
      if (data.status) {
        window.location.href = data.data
      }
    } catch (error) {
    } finally {
      setLoadingLoginGoogle(false)
    }
  }

  return (
    <>
      <div className="flex w-full max-w-xs flex-col items-center gap-3">
        <div className="w-full text-center">
          <h1 className="text-3xl font-bold">{$t({ id: "Welcome back" })}</h1>
          <p className="text-sm">{$t({ id: "Enter your account details." })}</p>
        </div>
        <div className="flex w-full flex-col gap-4 font-bold">
          <ButtonGoogle disabled={loadingLoginGoogle} text={$t({ id: "Log in with Google" })} onClick={handleLoginGoogleClick} />
        </div>
        <Divider text="OR" />
        <form className="w-full" onSubmit={handleSubmit} noValidate>
          <ErrorMessage error={error} />
          <div className="mb-2">
            <label htmlFor="email" className="text-xs">
              {$t({ id: "Email" })}
            </label>
            <Input id="email" type="email" className="bg-white" onChange={handleChange} value={values.email} />
            <ErrorMessage error={errors.email} />
          </div>
          <div className="mb-2">
            <div className="flex justify-between items-center">
              <label htmlFor="password" className="text-xs">
                {$t({ id: "Password" })}
              </label>
            </div>
            <Input type="password" id="password" className="bg-white" autoComplete="current-password" onChange={handleChange} value={values.password} />
            <ErrorMessage error={errors.password} />
          </div>
          <div className="space-x-1 mb-4">
            <input
              type="checkbox"
              name="rememberMe"
              id="rememberMe"
              className="m-0 cursor-pointer align-middle accent-current"
              onChange={handleChange}
              checked={values.rememberMe}
            />
            <label htmlFor="rememberMe" className="text-xs font-bold cursor-pointer select-none">
              {$t({ id: "Remember me" })}
            </label>
          </div>
          <Button type="submit" className="w-full" disabled={isSubmitting}>
            <p className="text-center block w-full">{$t({ id: isSubmitting ? "validating" : "Sign In" })}</p>
          </Button>
        </form>
      </div>
    </>
  )
}
