import { useState } from "react"
import { AxiosResponse } from "axios"
import type { ResponseTerminals, ResponseTerminal, Form } from "../TerminalType"
import axios from "@axios"
import { debounce } from "lodash"

export { default as InitState } from "../TerminalInitial.state"

export const useTerminals = (filters: TableFilters = {}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [terminals, setTerminals] = useState<Terminal[]>([])
  const [pagination, setPagination] = useState<Pagination>({
    count: 0,
    current_page: 1,
    per_page: 1,
    total: 1,
    total_pages: 1,
  })

  const fetchAll = async (params: TableFilters = filters): Promise<Terminal[] | void> => {
    if (loading) return
    try {
      setLoading(true)
      const { data: res } = await axios.get<ResponseTerminals>(`terminals`, { params })
      const { status, data, pagination } = res
      if (status) {
        setPagination(pagination)
        setTerminals(data)
        return data
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const debounceFetchAll = debounce(fetchAll, 350)

  const store = async (payload: Form): Promise<AxiosResponse<ResponseTerminal>> => {
    return await axios.post<ResponseTerminal>(`terminals`, payload)
  }

  const update = async (terminalId: ModelID | number, payload: Form): Promise<AxiosResponse<ResponseTerminal>> => {
    return await axios.put<ResponseTerminal>(`terminals/${terminalId}`, payload)
  }

  const destroy = async (terminalId: ModelID | number) => {
    try {
      setLoading(true)
      const { data: res } = await axios.delete<ResponseDelete>(`terminals/${terminalId}`)
      const { status, msg } = res
      if (!status) throw new Error(msg)
      return null
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  return {
    debounceFetchAll,
    setTerminals,
    terminals,
    setLoading,
    loading,
    fetchAll,
    store,
    destroy,
    update,
    pagination,
  }
}
