import React from "react"

interface ErrorFallbackProps {
  error: Error
  resetErrorBoundary?: () => void
}

const ErrorFallback: React.FC<ErrorFallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-gray-800">
      <div className="bg-white shadow-md rounded-lg p-8 max-w-md text-center">
        <h1 className="text-2xl font-bold text-red-600 mb-4">Oops! Something went wrong</h1>
        <p className="text-gray-600 mb-6">An unexpected error occurred. Please try again or contact support if the problem persists.</p>
        <pre className="bg-gray-100 text-sm text-red-500 p-4 rounded mb-6 overflow-auto">{error.message}</pre>
        {resetErrorBoundary ? (
          <button onClick={resetErrorBoundary} className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition">
            Try Again
          </button>
        ) : (
          <button onClick={() => window.location.reload()} className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition">
            Reload Page
          </button>
        )}
      </div>
    </div>
  )
}

export default ErrorFallback
