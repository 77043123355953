import { useState } from "react"
import { FormikHelpers, useFormik } from "formik"
import { AxiosError } from "axios"
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import { useIntl } from "react-intl"

import InitState from "../AuthInitial.state"
import { FormRegister, ResponseRegister } from "../AuthTypes"
import axios from "@axios"
import LaravelErrors from "@utils/laravel.errors"

import { Input, ErrorMessage, Button, Divider } from "@core/index"
import { ButtonGoogle } from "../components"
import useDocumentTitle from "@/hooks/useDocumentTitle"

export interface LoginGoogleResponse {
  status: boolean
  data: string
}

export const RegisterPage = (): JSX.Element => {
  const { formatMessage: $t } = useIntl()
  const navigate = useNavigate()
  const [error, setError] = useState<string>("")

  useDocumentTitle($t({ id: "Register" }))

  const onSubmit = async (values: FormRegister, actions: FormikHelpers<FormRegister>) => {
    actions.setSubmitting(true)
    setError("")
    try {
      const { data: resp } = await axios.post<ResponseRegister>("/register", values)
      const { status, msg } = resp
      if (!status) {
        if (typeof msg === "string") return setError(msg!)
        const errors = LaravelErrors.toObject(msg as LaravelValidationErrors)
        setErrors(errors)
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer
            toast.onmouseleave = Swal.resumeTimer
          },
        })
        Toast.fire({
          icon: "success",
          title: $t({ id: "Your account has been created" }),
        })
        navigate("/sign-in")
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        setError(error.response?.data?.error)
      }
    } finally {
      actions.setSubmitting(false)
    }
  }

  const { values, handleChange, handleSubmit, isSubmitting, errors, setErrors } = useFormik<FormRegister>({
    initialValues: InitState.FormRegister,
    onSubmit,
    validateOnChange: false,
  })

  const [loadingLoginGoogle, setLoadingLoginGoogle] = useState<boolean>(false)
  const handleLoginGoogleClick = async () => {
    try {
      setLoadingLoginGoogle(true)
      const { data } = await axios.post<LoginGoogleResponse>(`google_auth`, { from: "t4web" })
      if (data.status) {
        window.location.href = data.data
      }
    } catch (error) {
    } finally {
      setLoadingLoginGoogle(false)
    }
  }

  return (
    <>
      <div className="flex w-full max-w-md flex-col items-center gap-3">
        <div className="w-full text-center">
          <h1 className="text-3xl font-bold">{$t({ id: "Create account" })}</h1>
        </div>
        <div className="flex w-full gap-1 font-bold">
          <ButtonGoogle disabled={loadingLoginGoogle} text={$t({ id: "Log in with Google" })} onClick={handleLoginGoogleClick} />
        </div>
        <Divider text="OR" />
        <form className="w-full space-y-3" onSubmit={handleSubmit} noValidate>
          <ErrorMessage error={error} />
          <div className="grid grid-cols-2 gap-y-3 gap-x-1">
            <div className="flex flex-col">
              <label htmlFor="name" className="text-xs">
                {$t({ id: "Name" })}
              </label>
              <Input id="name" className="bg-white" type="name" onChange={handleChange} value={values.name} />
              <ErrorMessage error={errors.name} />
            </div>
            <div className="flex flex-col">
              <label htmlFor="email" className="text-xs">
                {$t({ id: "Email" })}
              </label>
              <Input id="email" className="bg-white" type="email" onChange={handleChange} value={values.email} />
              <ErrorMessage error={errors.email} />
            </div>
            <div className="flex flex-col">
              <label htmlFor="company_name" className="text-xs">
                {$t({ id: "Company name" })}
              </label>
              <Input id="company_name" className="bg-white" type="text" onChange={handleChange} value={values.company_name} />
              <ErrorMessage error={errors.company_name} />
            </div>
            <div className="flex flex-col">
              <label htmlFor="company_email" className="text-xs">
                {$t({ id: "Company email" })}
              </label>
              <Input id="company_email" className="bg-white" type="email" onChange={handleChange} value={values.company_email} />
              <ErrorMessage error={errors.company_email} />
            </div>
          </div>
          <div className="flex flex-col">
            <label htmlFor="company_address" className="text-xs">
              {$t({ id: "Company address" })}
            </label>
            <Input type="text" className="bg-white" id="company_address" onChange={handleChange} value={values.company_address} />
            <ErrorMessage error={errors.company_address} />
          </div>
          <div className="grid grid-cols-2 gap-1 gap-y-3">
            <div className="flex flex-col">
              <label htmlFor="company_state" className="text-xs">
                {$t({ id: "Company state" })}
              </label>
              <Input type="text" className="bg-white" id="company_state" onChange={handleChange} value={values.company_state} />
              <ErrorMessage error={errors.company_state} />
            </div>
            <div className="flex flex-col">
              <label htmlFor="company_city" className="text-xs">
                {$t({ id: "Company city" })}
              </label>
              <Input type="text" className="bg-white" id="company_city" onChange={handleChange} value={values.company_city} />
              <ErrorMessage error={errors.company_city} />
            </div>
            <div className="flex flex-col">
              <label htmlFor="company_zip_code" className="text-xs">
                {$t({ id: "Company zip code" })}
              </label>
              <Input type="text" className="bg-white" id="company_zip_code" onChange={handleChange} value={values.company_zip_code} />
              <ErrorMessage error={errors.company_zip_code} />
            </div>
            <div className="flex flex-col">
              <label htmlFor="company_country" className="text-xs">
                {$t({ id: "Company country" })}
              </label>
              <Input type="text" className="bg-white" id="company_country" onChange={handleChange} value={values.company_country} />
              <ErrorMessage error={errors.company_country} />
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="text-xs">
              {$t({ id: "Password" })}
            </label>
            <Input type="password" className="bg-white" id="password" onChange={handleChange} value={values.password} />
            <ErrorMessage error={errors.password} />
          </div>
          <Button type="submit" className="w-full justify-center" disabled={isSubmitting}>
            {$t({ id: isSubmitting ? "validating" : "Register" })}
          </Button>
        </form>
      </div>
    </>
  )
}
