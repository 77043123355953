import type { IKeys } from "../DispatchType"

export const KEYS: IKeys = {
  SELECTED_TERMINAL_ID: "SELECTED_TERMINAL_ID",
  RUN_QUEUE_DELAY_IN_SECONDS: "RUN_QUEUE_DELAY_IN_SECONDS",
  DOORS_FILTERS: "DOORS_FILTERS",
  DOORS_SEARCH: "DOORS_SEARCH",
  TRIPS_FILTERS: "TRIPS_FILTERS",
  TRIPS_SEARCH: "TRIPS_SEARCH",
  TAB_TRIPS_INDEX: "TAB_TRIPS_INDEX",
  TAB_ACTION_INDEX: "TAB_ACTION_INDEX",
  TAB_RESOURCES_INDEX: "TAB_RESOURCES_INDEX",
  CURRENT_TRIP_SELECTED_ID: "CURRENT_TRIP_SELECTED_ID",
  FREIGHT_BILLS_FILTERS: "FREIGHT_BILLS_FILTERS",
  FREIGHT_BILLS_SEARCH: "FREIGHT_BILLS_SEARCH",
  TRIPS_HISTORY_FILTERS: "TRIPS_HISTORY_FILTERS",
  DRIVERS_HISTORY_FILTERS: "DRIVERS_HISTORY_FILTERS",
  TRAILERS_HISTORY_FILTERS: "TRAILERS_HISTORY_FILTERS",
  TRUCKS_HISTORY_FILTERS: "TRUCKS_HISTORY_FILTERS",
  TAB_ACTION_HISTORY_INDEX: "TAB_ACTION_HISTORY_INDEX",
  FREIGHTS_FILTERS: "FREIGHTS_FILTERS",
  CARRIERS_FILTERS: "CARRIERS_FILTERS",
  FREIGHTS_SEARCH: "FREIGHTS_SEARCH",
  FREIGHTS_SEARCH_TYPE: "FREIGHTS_SEARCH_TYPE",
}

export enum REORDER_COLUMNS {
  STATUS_HISTORY_TRIPS = "DISPATCH_STATUS_HISTORY_TRIPS",
  STATUS_HISTORY_DRIVERS = "DISPATCH_STATUS_HISTORY_DRIVERS",
  STATUS_HISTORY_TRUCKS = "DISPATCH_STATUS_HISTORY_TRUCKS",
  STATUS_HISTORY_TRAILERS = "DISPATCH_STATUS_HISTORY_TRAILERS",
  STATUS_HISTORY_FREIGHTS = "DISPATCH_STATUS_HISTORY_FREIGHTS",
  TRIPS = "DISPATCH_TRIPS",
  DOORS = "DISPATCH_DOORS",
  FREIGHT_BILLS = "DISPATCH_FREIGHTS_BILLS",
  DISPATCHES = "DISPATCH_DISPATCHES",
  LOADS = "DISPATCH_LOADS",
  LOADS_PICKUPS = "DISPATCH_FREIGHTS_PICKUPS",
  LOADS_DELIVERIES = "DISPATCH_FREIGHTS_DELIVERIES",
  DRIVERS = "DISPATCH_DRIVERS",
  CARRIERS = "DISPATCH_CARRIERS",
  TRUCKS = "DISPATCH_TRUCKS",
  TRAILERS = "DISPATCH_TRAILERS",
  EQUIPMENTS = "DISPATCH_EQUIPMENTS",
}

export const REORDER_COLUMNS_V2 = {
  COLUMNS_EQUIPMENTS: "COLUMNS_EQUIPMENTS",
}

export type TKeyReorderColumnsV2 = keyof typeof REORDER_COLUMNS_V2

const COLUMNS_DISPATCHES: Column[] = [
  {
    caption: "Name",
    keySize: "dispatch_tbl_dispatches_name",
    field: "name",
    keyValue: "name",
    inlineSize: 200,
    isHidden: false,
  },
  {
    caption: "Email",
    keySize: "dispatch_tbl_dispatches_email",
    field: "email",
    keyValue: "email",
    inlineSize: 200,
    isHidden: false,
  },
  {
    caption: "status",
    keySize: "dispatch_tbl_dispatches_status",
    field: "status",
    keyValue: "active",
    inlineSize: 120,
    isHidden: false,
  },
  {
    caption: "Role",
    keySize: "dispatch_tbl_dispatches_role_name",
    keyValue: "roleName",
    inlineSize: 200,
    isHidden: true,
  },
]

const COLUMNS_LOADS_DELIVERIES: Column[] = [
  {
    caption: "Bill",
    keySize: "dispatch_tbl_loads_bill_number",
    field: "bill_number",
    classesChildren: "text-start",
    keyValue: "bill_number",
    inlineSize: 100,
    isHidden: false,
    modelKey: "id",
  },
  {
    caption: "Files",
    keySize: "dispatch_tbl_loads_files",
    classesChildren: "text-start",
    keyValue: "files",
    inlineSize: 70,
    isHidden: false,
  },
  {
    caption: "Status",
    keySize: "dispatch_tbl_loads_status",
    field: "statusCode",
    classesChildren: "text-start",
    keyValue: "statusCode",
    inlineSize: 120,
    isHidden: false,
  },
  {
    caption: "Pallets",
    field: "pallets",
    keySize: "dispatch_tbl_loads_pallets",
    classesChildren: "text-start",
    inlineSize: 90,
    keyValue: "pallets",
    isHidden: false,
  },
  {
    caption: "Weight",
    field: "weight",
    keySize: "dispatch_tbl_loads_weight",
    classesChildren: "text-start",
    inlineSize: 90,
    keyValue: "weight",
    isHidden: false,
  },
  {
    caption: "Charges",
    keySize: "dispatch_tbl_loads_charges",
    field: "charges",
    classesChildren: "text-start",
    inlineSize: 90,
    keyValue: "charges",
    isHidden: false,
  },
  {
    caption: "Pickup Name",
    keySize: "dispatch_tbl_loads_origin_name",
    field: "origin_name",
    classesChildren: "",
    keyValue: "originName",
    inlineSize: 180,
    isHidden: true,
  },
  {
    caption: "pickup date start",
    keySize: "dispatch_tbl_loads_pickup_date",
    field: "date_pickup_start",
    classesChildren: "",
    keyValue: "date_pickup_start",
    inlineSize: 170,
    isHidden: true,
  },
  {
    caption: "pickup date end",
    keySize: "dispatch_tbl_loads_pickup_time",
    field: "date_pickup_end",
    classesChildren: "",
    keyValue: "date_pickup_end",
    inlineSize: 170,
    isHidden: true,
  },
  {
    caption: "Pickup Address",
    keySize: "dispatch_tbl_loads_origin_address_1",
    field: "origin_address_1",
    classesChildren: "",
    keyValue: "locationOrigin",
    inlineSize: 200,
    isHidden: true,
  },
  {
    caption: "Delivery Name",
    keySize: "dispatch_tbl_loads_destination_name",
    field: "destination_name",
    classesChildren: "",
    keyValue: "destinationName",
    inlineSize: 180,
    isHidden: false,
  },
  {
    caption: "delivery date start",
    keySize: "dispatch_tbl_loads_delivery_date",
    field: "date_delivery_start",
    classesChildren: "",
    keyValue: "date_delivery_start",
    inlineSize: 170,
    isHidden: false,
  },
  {
    caption: "deliver date end",
    keySize: "dispatch_tbl_loads_delivery_time",
    field: "date_delivery_end",
    classesChildren: "",
    keyValue: "date_delivery_end",
    inlineSize: 170,
    isHidden: false,
  },
  {
    caption: "Delivery Address",
    keySize: "dispatch_tbl_loads_destination_address_1",
    field: "destination_address_1",
    classesChildren: "",
    keyValue: "locationDestination",
    inlineSize: 200,
    isHidden: false,
  },
  {
    caption: "Service Level",
    keySize: "dispatch_tbl_loads_serviceLevel_name",
    field: "serviceLevel_name",
    classesChildren: "text-start",
    keyValue: "serviceLevel",
    inlineSize: 120,
    isHidden: false,
  },
  {
    caption: "Margin",
    keySize: "dispatch_tbl_loads_margin",
    field: "margin",
    classesChildren: "",
    keyValue: "margin",
    inlineSize: 100,
    isHidden: true,
  },
  {
    caption: "creator name",
    keySize: "dispatch_tbl_loads_created_by_name",
    classesChildren: "",
    keyValue: "createdByName",
    inlineSize: 120,
    isHidden: true,
  },
  {
    caption: "creator email",
    keySize: "dispatch_tbl_loads_created_by_email",
    classesChildren: "",
    keyValue: "createdByEmail",
    inlineSize: 130,
    isHidden: true,
  },
  {
    caption: "bill to name",
    keySize: "dispatch_tbl_loads_bill_to_name",
    classesChildren: "",
    keyValue: "billName",
    inlineSize: 150,
    isHidden: true,
  },
  {
    caption: "bill to email",
    keySize: "dispatch_tbl_loads_bill_to_email",
    classesChildren: "",
    keyValue: "billEmail",
    inlineSize: 130,
    isHidden: true,
  },
  {
    caption: "customer name",
    keySize: "dispatch_tbl_loads_customer_name",
    classesChildren: "",
    keyValue: "customerName",
    inlineSize: 150,
    isHidden: true,
  },
  {
    caption: "customer email",
    keySize: "dispatch_tbl_loads_customer_email",
    classesChildren: "",
    keyValue: "customerEmail",
    inlineSize: 130,
    isHidden: true,
  },
  {
    caption: "customer phone",
    keySize: "dispatch_tbl_loads_customer_phone",
    classesChildren: "",
    keyValue: "customerPhone",
    inlineSize: 130,
    isHidden: true,
  },
]

const COLUMNS_LOADS_PICKUPS: Column[] = [
  {
    caption: "Bill",
    keySize: "dispatch_tbl_loads_bill_number",
    field: "bill_number",
    classesChildren: "text-start",
    keyValue: "bill_number",
    inlineSize: 100,
    isHidden: false,
    modelKey: "id",
  },
  {
    caption: "Files",
    keySize: "dispatch_tbl_loads_files",
    classesChildren: "text-start",
    keyValue: "files",
    inlineSize: 70,
    isHidden: false,
  },
  {
    caption: "Status",
    keySize: "dispatch_tbl_loads_status",
    field: "statusCode",
    classesChildren: "text-start",
    keyValue: "statusCode",
    inlineSize: 120,
    isHidden: false,
  },
  {
    caption: "Pallets",
    field: "pallets",
    keySize: "dispatch_tbl_loads_pallets",
    classesChildren: "text-start",
    keyValue: "pallets",
    inlineSize: 90,
    isHidden: false,
  },
  {
    caption: "Weight",
    field: "weight",
    keySize: "dispatch_tbl_loads_weight",
    classesChildren: "text-start",
    keyValue: "weight",
    inlineSize: 90,
    isHidden: false,
  },
  {
    caption: "Charges",
    keySize: "dispatch_tbl_loads_charges",
    field: "charges",
    classesChildren: "text-start",
    keyValue: "charges",
    inlineSize: 90,
    isHidden: false,
  },
  {
    caption: "Pickup Name",
    keySize: "dispatch_tbl_loads_origin_name",
    field: "origin_name",
    classesChildren: "",
    keyValue: "originName",
    inlineSize: 180,
    isHidden: false,
  },
  {
    caption: "pickup date start",
    keySize: "dispatch_tbl_loads_pickup_date",
    field: "date_pickup_start",
    classesChildren: "",
    keyValue: "date_pickup_start",
    inlineSize: 170,
    isHidden: false,
  },
  {
    caption: "pickup date end",
    keySize: "dispatch_tbl_loads_pickup_time",
    field: "date_pickup_end",
    classesChildren: "",
    keyValue: "date_pickup_end",
    inlineSize: 170,
    isHidden: false,
  },
  {
    caption: "Pickup Address",
    keySize: "dispatch_tbl_loads_origin_address_1",
    field: "origin_address_1",
    classesChildren: "",
    keyValue: "locationOrigin",
    inlineSize: 200,
    isHidden: false,
  },
  {
    caption: "Delivery Name",
    keySize: "dispatch_tbl_loads_destination_name",
    field: "destination_name",
    classesChildren: "",
    keyValue: "destinationName",
    inlineSize: 180,
    isHidden: true,
  },
  {
    caption: "delivery date start",
    keySize: "dispatch_tbl_loads_delivery_date",
    field: "date_delivery_start",
    classesChildren: "",
    keyValue: "date_delivery_start",
    inlineSize: 170,
    isHidden: true,
  },
  {
    caption: "deliver date end",
    keySize: "dispatch_tbl_loads_delivery_time",
    field: "date_delivery_end",
    classesChildren: "",
    keyValue: "date_delivery_end",
    inlineSize: 170,
    isHidden: true,
  },
  {
    caption: "Delivery Address",
    keySize: "dispatch_tbl_loads_destination_address_1",
    field: "destination_address_1",
    classesChildren: "",
    keyValue: "locationDestination",
    inlineSize: 200,
    isHidden: true,
  },
  {
    caption: "Service Level",
    keySize: "dispatch_tbl_loads_serviceLevel_name",
    field: "serviceLevel_name",
    classesChildren: "text-start",
    keyValue: "serviceLevel",
    inlineSize: 120,
    isHidden: false,
  },
  {
    caption: "Margin",
    keySize: "dispatch_tbl_loads_margin",
    field: "margin",
    classesChildren: "",
    keyValue: "margin",
    inlineSize: 100,
    isHidden: true,
  },
  {
    caption: "creator name",
    keySize: "dispatch_tbl_loads_created_by_name",
    classesChildren: "",
    keyValue: "createdByName",
    inlineSize: 120,
    isHidden: true,
  },
  {
    caption: "creator email",
    keySize: "dispatch_tbl_loads_created_by_email",
    classesChildren: "",
    keyValue: "createdByEmail",
    inlineSize: 130,
    isHidden: true,
  },
  {
    caption: "bill to name",
    keySize: "dispatch_tbl_loads_bill_to_name",
    classesChildren: "",
    keyValue: "billName",
    inlineSize: 150,
    isHidden: true,
  },
  {
    caption: "bill to email",
    keySize: "dispatch_tbl_loads_bill_to_email",
    classesChildren: "",
    keyValue: "billEmail",
    inlineSize: 130,
    isHidden: true,
  },
  {
    caption: "customer name",
    keySize: "dispatch_tbl_loads_customer_name",
    classesChildren: "",
    keyValue: "customerName",
    inlineSize: 150,
    isHidden: true,
  },
  {
    caption: "customer email",
    keySize: "dispatch_tbl_loads_customer_email",
    classesChildren: "",
    keyValue: "customerEmail",
    inlineSize: 130,
    isHidden: true,
  },
  {
    caption: "customer phone",
    keySize: "dispatch_tbl_loads_customer_phone",
    classesChildren: "",
    keyValue: "customerPhone",
    inlineSize: 130,
    isHidden: true,
  },
]

const COLUMNS_DRIVERS: Column[] = [
  {
    caption: "ID",
    keySize: "dispatch_tbl_drivers_id",
    field: "id",
    keyValue: "id",
    inlineSize: 65,
    isHidden: false,
  },
  {
    caption: "Driver",
    keySize: "dispatch_tbl_drivers_name",
    field: "name",
    keyValue: "name",
    inlineSize: 180,
    isHidden: false,
  },
  {
    caption: "Pictures",
    keySize: "dispatch_tbl_drivers_pictures",
    field: "pictures",
    keyValue: "buttonPictures",
    inlineSize: 100,
    isHidden: false,
  },
  {
    caption: "Phone",
    keySize: "dispatch_tbl_drivers_phone_number",
    keyValue: "phoneNumber",
    inlineSize: 150,
    isHidden: false,
  },
  {
    caption: "Last Trip",
    keySize: "dispatch_tbl_drivers_last_trip_id",
    field: "lastTripId",
    inlineSize: 100,
    keyValue: "lastTripId",
    isHidden: false,
  },
  {
    caption: "Current Trip",
    keySize: "dispatch_tbl_drivers_current_trip_id",
    field: "currentTripId",
    inlineSize: 100,
    keyValue: "currentTripId",
    isHidden: false,
  },
  {
    caption: "Next Trip",
    keySize: "dispatch_tbl_drivers_next_trip_id",
    field: "nextTripId",
    inlineSize: 100,
    keyValue: "nextTripId",
    isHidden: false,
  },
  {
    caption: "Address",
    keySize: "dispatch_tbl_drivers_address_1",
    classesChildren: "flex gap-1 items-center",
    keyValue: "address_1",
    inlineSize: 300,
    isHidden: false,
  },
  {
    caption: "City",
    keySize: "dispatch_tbl_drivers_city",
    field: "city",
    keyValue: "city",
    inlineSize: 90,
    isHidden: false,
  },
  {
    caption: "State",
    keySize: "dispatch_tbl_drivers_state",
    field: "state",
    keyValue: "state",
    inlineSize: 90,
    isHidden: false,
  },
  {
    caption: "Zip Code",
    keySize: "dispatch_tbl_drivers_zip_code",
    field: "zip_code",
    keyValue: "zip_code",
    inlineSize: 110,
    isHidden: false,
  },
  {
    caption: "Status",
    keySize: "dispatch_tbl_drivers_status_code",
    field: "statusCode",
    keyValue: "statusCode",
    inlineSize: 120,
    isHidden: false,
  },
  {
    caption: "terminal name",
    keySize: "dispatch_tbl_drivers_terminal_name",
    field: "terminalName",
    keyValue: "terminalName",
    inlineSize: 120,
    isHidden: true,
  },
  {
    caption: "terminal address",
    keySize: "dispatch_tbl_drivers_terminal_address",
    keyValue: "terminalAddress",
    inlineSize: 180,
    isHidden: true,
  },
  {
    caption: "creator name",
    keySize: "dispatch_tbl_drivers_creator_name",
    keyValue: "creatorName",
    inlineSize: 130,
    isHidden: true,
  },
  {
    caption: "creator email",
    keySize: "dispatch_tbl_drivers_creator_email",
    keyValue: "creatorEmail",
    inlineSize: 120,
    isHidden: true,
  },
  {
    caption: "Email",
    keySize: "dispatch_tbl_drivers_email",
    keyValue: "email",
    inlineSize: 120,
    isHidden: true,
  },
]

const COLUMNS_TRUCKS: Column[] = [
  {
    caption: "Truck",
    keySize: "dispatch_tbl_trucks_name",
    field: "name",
    keyValue: "name",
    inlineSize: 150,
    isHidden: false,
  },
  {
    caption: "Pictures",
    keySize: "dispatch_tbl_trucks_pictures",
    field: "pictures",
    keyValue: "buttonPictures",
    inlineSize: 100,
    isHidden: false,
  },
  {
    caption: "Current Trip",
    keySize: "dispatch_tbl_trucks_current_trip_id",
    field: "currentTripId",
    inlineSize: 100,
    keyValue: "currentTripId",
    isHidden: false,
  },
  {
    caption: "Next Trip",
    keySize: "dispatch_tbl_trucks_next_trip_id",
    field: "nextTripId",
    inlineSize: 100,
    keyValue: "nextTripId",
    isHidden: false,
  },
  {
    caption: "Plate",
    keySize: "dispatch_tbl_trucks_plate",
    field: "plate",
    keyValue: "plate",
    inlineSize: 150,
    isHidden: false,
  },
  {
    caption: "Model",
    keySize: "dispatch_tbl_trucks_model",
    field: "model",
    keyValue: "model",
    inlineSize: 150,
    isHidden: false,
  },
  {
    caption: "Make",
    keySize: "dispatch_tbl_trucks_make",
    field: "make",
    keyValue: "make",
    inlineSize: 150,
    isHidden: false,
  },
  {
    caption: "Year",
    keySize: "dispatch_tbl_trucks_year",
    field: "year",
    keyValue: "year",
    inlineSize: 150,
    isHidden: false,
  },
  {
    caption: "Status",
    keySize: "dispatch_tbl_trucks_status_code",
    field: "statusCode",
    keyValue: "statusCode",
    inlineSize: 120,
    isHidden: false,
  },
  {
    caption: "VIN",
    keySize: "dispatch_tbl_trucks_vin",
    keyValue: "VIN",
    inlineSize: 110,
    isHidden: true,
  },
  {
    caption: "terminal name",
    keySize: "dispatch_tbl_trucks_terminal_name",
    field: "terminalName",
    keyValue: "terminalName",
    inlineSize: 150,
    isHidden: true,
  },
  {
    caption: "terminal address",
    keySize: "dispatch_tbl_trucks_terminal_address",
    keyValue: "terminalAddress",
    inlineSize: 250,
    isHidden: true,
  },
]

const COLUMNS_TRAILERS: Column[] = [
  {
    caption: "Trailer",
    keySize: "dispatch_tbl_trailers_name",
    field: "name",
    keyValue: "name",
    inlineSize: 150,
    isHidden: false,
  },
  {
    caption: "Pictures",
    keySize: "dispatch_tbl_trucks_pictures",
    field: "pictures",
    keyValue: "buttonPictures",
    inlineSize: 100,
    isHidden: false,
  },
  {
    caption: "Current Trip",
    keySize: "dispatch_tbl_trailers_current_trip_id",
    field: "currentTripId",
    inlineSize: 100,
    keyValue: "currentTripId",
    isHidden: false,
  },
  {
    caption: "Next Trip",
    keySize: "dispatch_tbl_trailers_next_trip_id",
    field: "nextTripId",
    inlineSize: 100,
    keyValue: "nextTripId",
    isHidden: false,
  },
  {
    caption: "Plate",
    keySize: "dispatch_tbl_trailers_plate",
    field: "plate",
    keyValue: "plate",
    inlineSize: 150,
    isHidden: false,
  },
  {
    caption: "Model",
    keySize: "dispatch_tbl_trailers_model",
    field: "model",
    keyValue: "model",
    inlineSize: 150,
    isHidden: false,
  },
  {
    caption: "Make",
    keySize: "dispatch_tbl_trailers_make",
    field: "make",
    keyValue: "make",
    inlineSize: 150,
    isHidden: false,
  },
  {
    caption: "Year",
    keySize: "dispatch_tbl_trailers_year",
    field: "year",
    keyValue: "year",
    inlineSize: 150,
    isHidden: false,
  },
  {
    caption: "Status",
    keySize: "dispatch_tbl_trailers_status_code",
    field: "statusCode",
    classesChildren: "text-start",
    keyValue: "statusCode",
    inlineSize: 120,
    isHidden: false,
  },
  {
    caption: "terminal name",
    keySize: "dispatch_tbl_trailers_terminal_name",
    field: "terminalName",
    keyValue: "terminalName",
    inlineSize: 150,
    isHidden: true,
  },
  {
    caption: "terminal address",
    keySize: "dispatch_tbl_trailers_terminal_address",
    keyValue: "terminalAddress",
    inlineSize: 250,
    isHidden: true,
  },
]

const COLUMNS_FREIGHT_BILLS: Column[] = [
  {
    caption: "sequence",
    keySize: "dispatch_tbl_freight_bills_sequence",
    field: "route_sequence",
    classes: "min-w-[95px] w-[95px]",
    classesChildren: "",
    keyValue: "route_sequence",
    inlineSize: 95,
    isHidden: false,
  },
  {
    caption: "Type",
    keySize: "dispatch_tbl_freight_bills_tx_type",
    field: "tx_type",
    classes: "min-w-[70px] w-[70px]",
    classesChildren: "",
    keyValue: "tx_type",
    inlineSize: 70,
    isHidden: false,
  },
  {
    caption: "Bill Number",
    keySize: "dispatch_tbl_freight_bills_bill_number",
    field: "bill_number",
    classes: "min-w-[110px] w-[110px]",
    classesChildren: "",
    keyValue: "bill_number",
    inlineSize: 110,
    isHidden: false,
  },
  {
    caption: "Detail Line ID",
    keySize: "dispatch_tbl_freight_bills_detail_line_id",
    field: "id",
    classes: "min-w-[120px] w-[120px]",
    classesChildren: "",
    keyValue: "id",
    inlineSize: 120,
    isHidden: false,
  },
  {
    caption: "Status",
    keySize: "dispatch_tbl_freight_bills_status",
    field: "status_code",
    classes: "min-w-[120px] w-[120px]",
    classesChildren: "",
    keyValue: "status_code",
    inlineSize: 120,
    isHidden: false,
  },
  {
    caption: "miles",
    keySize: "dispatch_tbl_freight_bills_miles",
    field: "miles",
    classes: "min-w-[70px] w-[70px]",
    classesChildren: "",
    keyValue: "miles",
    inlineSize: 70,
    isHidden: false,
  },
  {
    caption: "Loc Name",
    keySize: "dispatch_tbl_freight_bills_loc_name",
    field: "locName",
    classes: "min-w-[200px] w-[200px]",
    classesChildren: "",
    keyValue: "locName",
    inlineSize: 200,
    isHidden: false,
  },
  {
    caption: "Loc Address",
    keySize: "dispatch_tbl_freight_bills_loc_address",
    field: "locAddress",
    classes: "min-w-[200px] w-[200px]",
    classesChildren: "",
    keyValue: "locAddress",
    inlineSize: 200,
    isHidden: false,
  },
  {
    caption: "Loc City",
    keySize: "dispatch_tbl_freight_bills_loc_city",
    field: "locCity",
    classes: "min-w-[150px] w-[200px]",
    classesChildren: "",
    keyValue: "locCity",
    inlineSize: 200,
    isHidden: false,
  },
  {
    caption: "Loc Zip",
    keySize: "dispatch_tbl_freight_bills_loc_zip",
    field: "locZip",
    classes: "min-w-[100px] w-[100px]",
    classesChildren: "",
    keyValue: "locZip",
    inlineSize: 100,
    isHidden: false,
  },
  {
    caption: "Loc State",
    keySize: "dispatch_tbl_freight_bills_loc_state",
    field: "locState",
    classes: "min-w-[100px] w-[100px]",
    classesChildren: "",
    keyValue: "locState",
    inlineSize: 100,
    isHidden: false,
  },
  {
    caption: "Description",
    keySize: "dispatch_tbl_freight_bills_description",
    field: "description",
    classes: "min-w-[200px] w-[200px]",
    classesChildren: "",
    keyValue: "description",
    inlineSize: 200,
    isHidden: false,
  },
  {
    caption: "Weight",
    keySize: "dispatch_tbl_freight_bills_weight",
    field: "weight",
    classes: "min-w-[85px] w-[85px]",
    classesChildren: "text-start",
    keyValue: "weight",
    inlineSize: 85,
    isHidden: false,
  },
  {
    caption: "Pieces",
    keySize: "dispatch_tbl_freight_bills_pieces",
    field: "pieces",
    classes: "min-w-[85px] w-[85px]",
    classesChildren: "text-start",
    keyValue: "pieces",
    inlineSize: 85,
    isHidden: false,
  },
  {
    caption: "Pallets",
    keySize: "dispatch_tbl_freight_bills_pallets",
    field: "pallets",
    classes: "min-w-[85px] w-[85px]",
    classesChildren: "text-start",
    keyValue: "pallets",
    inlineSize: 85,
    isHidden: false,
  },
  {
    caption: "Length",
    keySize: "dispatch_tbl_freight_bills_length",
    field: "_length",
    classes: "min-w-[85px] w-[85px]",
    classesChildren: "text-start",
    keyValue: "_length",
    inlineSize: 85,
    isHidden: false,
  },
  {
    caption: "Width",
    keySize: "dispatch_tbl_freight_bills_width",
    field: "width",
    classes: "min-w-[85px] w-[85px]",
    classesChildren: "text-start",
    keyValue: "width",
    inlineSize: 85,
    isHidden: false,
  },
  {
    caption: "Height",
    keySize: "dispatch_tbl_freight_bills_height",
    field: "height",
    classes: "min-w-[85px] w-[85px]",
    classesChildren: "text-start",
    keyValue: "height",
    inlineSize: 85,
    isHidden: false,
  },
  {
    caption: "delivery date and time",
    keySize: "dispatch_tbl_freight_bills_pickup",
    field: "date_pickup_start",
    classes: "min-w-[170px] w-[170px]",
    classesChildren: "",
    keyValue: "date_pickup_start_to_text",
    inlineSize: 170,
    isHidden: false,
  },
  {
    caption: "delivery date and time",
    keySize: "dispatch_tbl_freight_bills_delivery",
    field: "date_delivery_start",
    classes: "min-w-[170px] w-[170px]",
    classesChildren: "",
    keyValue: "date_delivery_start_to_text",
    inlineSize: 170,
    isHidden: false,
  },
  {
    caption: "terminal name",
    keySize: "dispatch_tbl_freight_bills_terminal_name",
    classes: "min-w-[120px] w-[120px]",
    classesChildren: "",
    keyValue: "terminalName",
    inlineSize: 120,
    isHidden: true,
  },
  {
    caption: "pickup city",
    keySize: "dispatch_tbl_freight_bills_pickup_origin_name",
    classes: "min-w-[200px] w-[200px]",
    classesChildren: "",
    keyValue: "origin_city",
    inlineSize: 200,
    isHidden: true,
  },
  {
    caption: "pickup state",
    keySize: "dispatch_tbl_freight_bills_pickup_origin_state",
    classes: "min-w-[200px] w-[200px]",
    classesChildren: "",
    keyValue: "origin_state",
    inlineSize: 200,
    isHidden: true,
  },
  {
    caption: "delivery city",
    keySize: "dispatch_tbl_freight_bills_delivery_destination_city",
    classes: "min-w-[200px] w-[200px]",
    classesChildren: "",
    keyValue: "destination_city",
    inlineSize: 200,
    isHidden: true,
  },
  {
    caption: "delivery state",
    keySize: "dispatch_tbl_freight_bills_delivery_destination_state",
    classes: "min-w-[120px] w-[120px]",
    classesChildren: "",
    keyValue: "destination_state",
    inlineSize: 120,
    isHidden: true,
  },
  {
    caption: "terminal address",
    keySize: "dispatch_tbl_freight_bills_terminal_address",
    classes: "min-w-[180px] w-[180px]",
    classesChildren: "",
    keyValue: "terminalAddress",
    inlineSize: 180,
    isHidden: true,
  },
  {
    caption: "creator name",
    keySize: "dispatch_tbl_freight_bills_creator_name",
    classes: "min-w-[130px] w-[130px]",
    classesChildren: "",
    keyValue: "creatorName",
    inlineSize: 130,
    isHidden: true,
  },
  {
    caption: "creator email",
    keySize: "dispatch_tbl_freight_bills_creator_email",
    classes: "min-w-[120px] w-[120px]",
    classesChildren: "",
    keyValue: "creatorEmail",
    inlineSize: 120,
    isHidden: true,
  },
  {
    caption: "creation date",
    keySize: "dispatch_tbl_freight_bills_creation_date",
    classes: "min-w-[120px] w-[120px]",
    classesChildren: "",
    keyValue: "creationDate",
    inlineSize: 170,
    isHidden: true,
  },
]

const COLUMNS_STATUS_HISTORY_TRIPS: Column[] = [
  {
    caption: "Code",
    keySize: "dispatch_tbl_status_history_trips_code",
    classes: "w-[100px] capitalize",
    classesChildren: "",
    keyValue: "id",
    isHidden: false,
  },
  {
    field: "status_code",
    caption: "Status",
    keySize: "dispatch_tbl_status_history_trips_status_code",
    classes: "w-[120px]",
    classesChildren: "",
    keyValue: "status_code",
    isHidden: false,
  },
  {
    caption: "time changed",
    keySize: "dispatch_tbl_status_history_trips_date_change",
    field: "time_changed",
    classes: "capitalize w-[130px]",
    classesChildren: "text-start first-letter:uppercase",
    keyValue: "timeChanged",
    isHidden: false,
  },
  {
    caption: "Zone",
    keySize: "dispatch_tbl_status_history_trips_zone",
    field: "zone",
    classes: "w-[80px]",
    classesChildren: "",
    keyValue: "zone",
    isHidden: false,
  },
  {
    caption: "Comment",
    keySize: "dispatch_tbl_status_history_trips_comments",
    classes: "",
    classesChildren: "",
    keyValue: "comments",
    isHidden: false,
  },
  {
    caption: "User",
    keySize: "dispatch_tbl_status_history_trips_username",
    classes: "w-[200px] capitalize",
    classesChildren: "",
    keyValue: "user",
    isHidden: false,
  },
  {
    caption: "ins date",
    keySize: "dispatch_tbl_status_history_trips_ins_date",
    field: "date_change",
    classes: "capitalize w-[130px]",
    classesChildren: "text-start first-letter:uppercase",
    keyValue: "insDate",
    isHidden: false,
  },
]

const COLUMNS_STATUS_HISTORY_DRIVERS: Column[] = [
  {
    caption: "Code",
    keySize: "dispatch_tbl_status_history_drivers_code",
    field: "code",
    classes: "w-[100px] capitalize",
    classesChildren: "",
    keyValue: "code",
    isHidden: false,
  },
  {
    caption: "driver name",
    keySize: "dispatch_tbl_status_history_drivers_driver_name",
    field: "driver_name",
    classes: "w-[170px] capitalize",
    classesChildren: "",
    keyValue: "driverName",
    isHidden: false,
  },
  {
    caption: "driver phone",
    keySize: "dispatch_tbl_status_history_drivers_driver_phone",
    classes: "w-[120px] capitalize",
    classesChildren: "",
    keyValue: "driverPhone",
    isHidden: false,
  },
  {
    caption: "Status",
    keySize: "dispatch_tbl_status_history_drivers_status",
    field: "status",
    classes: "w-[120px]",
    classesChildren: "",
    keyValue: "status",
    isHidden: false,
  },
  {
    caption: "time changed",
    keySize: "dispatch_tbl_status_history_drivers_time_changed",
    field: "time_changed",
    classes: "capitalize w-[130px]",
    classesChildren: "!text-start",
    keyValue: "time_changed",
    isHidden: false,
  },
  {
    caption: "Zones",
    keySize: "dispatch_tbl_status_history_drivers_zones",
    classes: "w-[200px] capitalize",
    classesChildren: "",
    keyValue: "defaultEmpty",
    isHidden: false,
  },
  {
    caption: "Comment",
    keySize: "dispatch_tbl_status_history_drivers_comment",
    classes: "",
    classesChildren: "first-letter:uppercase",
    keyValue: "stat_comment",
    isHidden: false,
  },
  {
    caption: "User",
    keySize: "dispatch_tbl_status_history_drivers_user",
    classes: "capitalize w-[170px]",
    classesChildren: "",
    keyValue: "userName",
    isHidden: false,
  },

  {
    caption: "ins date",
    keySize: "dispatch_tbl_status_history_drivers_ins_date",
    field: "created_at",
    classes: "capitalize w-[130px]",
    classesChildren: "!text-start",
    keyValue: "created_at",
    isHidden: false,
  },
]

const COLUMNS_STATUS_HISTORY_TRUCKS: Column[] = [
  {
    caption: "Code",
    keySize: "dispatch_tbl_status_history_trucks_code",
    field: "code",
    classes: "w-[100px] capitalize",
    classesChildren: "",
    keyValue: "code",
    isHidden: false,
  },
  {
    caption: "truck name",
    keySize: "dispatch_tbl_status_history_trucks_truck_name",
    field: "truck_name",
    classes: "w-[170px] capitalize",
    classesChildren: "",
    keyValue: "truckName",
    isHidden: false,
  },
  {
    caption: "Status",
    keySize: "dispatch_tbl_status_history_trucks_status",
    field: "status",
    classes: "w-[120px]",
    classesChildren: "",
    keyValue: "status",
    isHidden: false,
  },
  {
    caption: "time changed",
    keySize: "dispatch_tbl_status_history_trucks_time_changed",
    field: "time_changed",
    classes: "capitalize w-[130px]",
    classesChildren: "!text-start",
    keyValue: "time_changed",
    isHidden: false,
  },
  {
    caption: "Zones",
    keySize: "dispatch_tbl_status_history_trucks_zones",
    classes: "w-[200px] capitalize",
    classesChildren: "",
    keyValue: "defaultEmpty",
    isHidden: false,
  },
  {
    caption: "Comment",
    keySize: "dispatch_tbl_status_history_trucks_comment",
    classes: "",
    classesChildren: "first-letter:uppercase",
    keyValue: "stat_comment",
    isHidden: false,
  },
  {
    caption: "User",
    keySize: "dispatch_tbl_status_history_trucks_user",
    field: "user_name",
    classes: "capitalize w-[170px]",
    classesChildren: "",
    keyValue: "userName",
    isHidden: false,
  },
  {
    caption: "ins date",
    keySize: "dispatch_tbl_status_history_trucks_ins_date",
    field: "created_at",
    classes: "capitalize w-[130px]",
    classesChildren: "!text-start",
    keyValue: "created_at",
    isHidden: false,
  },
]

const COLUMNS_STATUS_HISTORY_TRAILERS: Column[] = [
  {
    caption: "Code",
    keySize: "dispatch_tbl_status_history_trailers_code",
    field: "code",
    classes: "w-[100px] capitalize",
    classesChildren: "",
    keyValue: "code",
    isHidden: false,
  },
  {
    caption: "trailer name",
    keySize: "dispatch_tbl_status_history_trailers_trailer_name",
    field: "trailer_name",
    classes: "w-[170px] capitalize",
    classesChildren: "",
    keyValue: "trailerName",
    isHidden: false,
  },
  {
    caption: "Status",
    keySize: "dispatch_tbl_status_history_trailers_status",
    field: "status",
    classes: "w-[120px]",
    classesChildren: "",
    keyValue: "status",
    isHidden: false,
  },
  {
    caption: "time changed",
    keySize: "dispatch_tbl_status_history_trailers_time_changed",
    field: "time_changed",
    classes: "capitalize w-[130px]",
    classesChildren: "!text-start",
    keyValue: "time_changed",
    isHidden: false,
  },
  {
    caption: "Zones",
    keySize: "dispatch_tbl_status_history_trailers_zones",
    classes: "w-[200px] capitalize",
    classesChildren: "",
    keyValue: "defaultEmpty",
    isHidden: false,
  },
  {
    caption: "Comment",
    keySize: "dispatch_tbl_status_history_trailers_comment",
    classes: "",
    classesChildren: "first-letter:uppercase",
    keyValue: "stat_comment",
    isHidden: false,
  },
  {
    caption: "User",
    keySize: "dispatch_tbl_status_history_trailers_user",
    field: "username",
    classes: "capitalize w-[170px]",
    classesChildren: "",
    keyValue: "userName",
    isHidden: false,
  },
  {
    caption: "ins date",
    keySize: "dispatch_tbl_status_history_trailers_ins_date",
    field: "created_at",
    classes: "capitalize w-[130px]",
    classesChildren: "!text-start",
    keyValue: "created_at",
    isHidden: false,
  },
]

const COLUMNS_STATUS_HISTORY_FREIGHTS: Column[] = [
  {
    caption: "status code",
    keySize: "dispatch_tbl_status_history_freights_status_code",
    field: "status_code",
    classes: "w-[150px] capitalize",
    classesChildren: "",
    keyValue: "status_code",
    isHidden: false,
  },
  {
    caption: "freight id",
    keySize: "dispatch_tbl_status_history_freights_freight_id",
    field: "freight_id",
    classes: "w-[100px] capitalize",
    classesChildren: "",
    keyValue: "freight_id",
    isHidden: false,
  },
  {
    caption: "trip id",
    keySize: "dispatch_tbl_status_history_freights_trip_id",
    field: "trip_id",
    classes: "w-[100px] capitalize",
    classesChildren: "",
    keyValue: "trip_id",
    isHidden: false,
  },
  {
    caption: "comment",
    keySize: "dispatch_tbl_status_history_freights_comment",
    classes: "capitalize",
    classesChildren: "",
    keyValue: "comments",
    isHidden: false,
  },
  {
    caption: "date change",
    keySize: "dispatch_tbl_status_history_freights_trip_date_change",
    field: "date_change",
    classes: "w-[180px] capitalize",
    classesChildren: "",
    keyValue: "date_change",
    isHidden: false,
  },
  {
    caption: "creator name",
    keySize: "dispatch_tbl_status_history_freights_created_by_name",
    classes: "min-w-[120px] w-[120px]",
    classesChildren: "",
    keyValue: "creatorName",
    isHidden: false,
  },
  {
    caption: "creator email",
    keySize: "dispatch_tbl_status_history_freights_created_by_email",
    classes: "min-w-[130px] w-[130px]",
    classesChildren: "",
    keyValue: "creatorEmail",
    isHidden: true,
  },
]

const COLUMNS_TRIPS: Column[] = [
  {
    caption: "Trip",
    keySize: "dispatch_tbl_trips_id",
    field: "id",
    keyValue: "id",
    isHidden: false,
    inlineSize: 75,
  },
  {
    caption: "Status",
    keySize: "dispatch_tbl_trips_status",
    field: "statusCode",
    keyValue: "statusCode",
    isHidden: false,
    inlineSize: 120,
  },
  {
    caption: "driver / phone number",
    keySize: "dispatch_tbl_trips_driver_name",
    field: "driverNames",
    keyValue: "driverNames",
    isHidden: false,
    inlineSize: 310,
  },
  {
    caption: "Truck",
    keySize: "dispatch_tbl_trips_truck_name",
    field: "truckNames",
    keyValue: "truckNames",
    isHidden: false,
    inlineSize: 80,
  },
  {
    caption: "Trailer",
    keySize: "dispatch_tbl_trips_trailer_name",
    field: "trailerNames",
    keyValue: "trailerNames",
    isHidden: false,
    inlineSize: 80,
  },
  {
    caption: "current zone",
    keySize: "dispatch_tbl_trips_current_zone",
    keyValue: "currentZone",
    isHidden: false,
    inlineSize: 130,
  },
  {
    caption: "origin zone",
    keySize: "dispatch_tbl_trips_origin_zone",
    keyValue: "startZone",
    isHidden: false,
    inlineSize: 130,
  },
  {
    caption: "end zone",
    keySize: "dispatch_tbl_trips_destination_zone",
    keyValue: "endZone",
    isHidden: false,
    inlineSize: 130,
  },
  {
    caption: "dispatch name",
    keySize: "dispatch_tbl_trips_dispatch_name",
    keyValue: "dispatchName",
    isHidden: false,
    inlineSize: 180,
  },
  {
    caption: "dispatch email",
    keySize: "dispatch_tbl_trips_dispatch_email",
    keyValue: "dispatchEmail",
    isHidden: true,
    inlineSize: 180,
  },
  {
    caption: "Length",
    keySize: "dispatch_tbl_trips_length",
    field: "a_length",
    keyValue: "a_length",
    isHidden: false,
    inlineSize: 100,
  },
  {
    caption: "Pieces",
    keySize: "dispatch_tbl_trips_pieces",
    field: "pieces",
    keyValue: "pieces",
    isHidden: false,
    inlineSize: 100,
  },
  {
    caption: "Pallets",
    keySize: "dispatch_tbl_trips_pallets",
    field: "pallets",
    keyValue: "pallets",
    isHidden: false,
    inlineSize: 100,
  },
  {
    caption: "cubes",
    keySize: "dispatch_tbl_trips_cubes",
    field: "cubes",
    keyValue: "cubes",
    isHidden: false,
    inlineSize: 100,
  },
  {
    caption: "creator name",
    keySize: "dispatch_tbl_trips_created_by_name",
    keyValue: "createdByName",
    isHidden: false,
    inlineSize: 120,
  },
  {
    caption: "creator email",
    keySize: "dispatch_tbl_trips_created_by_email",
    keyValue: "createdByEmail",
    isHidden: true,
    inlineSize: 140,
  },
  {
    caption: "terminal name",
    keySize: "dispatch_tbl_trips_terminal_name",
    keyValue: "terminalName",
    isHidden: true,
    inlineSize: 120,
  },
  {
    caption: "terminal address",
    keySize: "dispatch_tbl_trips_terminal_address1",
    keyValue: "terminalAddress",
    isHidden: true,
    inlineSize: 150,
  },
  {
    caption: "creation date",
    keySize: "dispatch_tbl_trips_creation_date",
    keyValue: "creationDate",
    isHidden: true,
    inlineSize: 150,
  },
]

const COLUMNS_DOORS: Column[] = [
  {
    caption: "door",
    keySize: "dispatch_tbl_doors_no",
    field: "door_no",
    classes: "capitalize",
    classesChildren: "",
    keyValue: "door_no",
    inlineSize: 125,
    isHidden: false,
  },
  {
    caption: "terminal name",
    keySize: "dispatch_tbl_doors_terminal_name",
    field: "terminalName",
    classes: "capitalize w-[200px]",
    classesChildren: "",
    keyValue: "terminalName",
    inlineSize: 200,
    isHidden: false,
  },
  {
    caption: "Trip",
    keySize: "dispatch_tbl_doors_trip_number",
    field: "trip_number",
    classes: "",
    classesChildren: "",
    keyValue: "defaultEmpty",
    inlineSize: 125,
    isHidden: false,
  },
  {
    caption: "Description",
    keySize: "dispatch_tbl_doors_description",
    field: "doorDescription",
    classes: "",
    classesChildren: "",
    keyValue: "doorDescription",
    inlineSize: 350,
    isHidden: false,
  },
  {
    caption: "Status",
    keySize: "dispatch_tbl_doors_status",
    field: "statusName",
    classes: "w-[120px]",
    classesChildren: "",
    keyValue: "statusName",
    inlineSize: 170,
    isHidden: false,
  },
  {
    caption: "Driver",
    keySize: "dispatch_tbl_doors_driver_name",
    classes: "",
    classesChildren: "",
    keyValue: "driverNames",
    inlineSize: 250,
    isHidden: false,
  },
  {
    caption: "Truck",
    keySize: "dispatch_tbl_doors_truck_name",
    classes: "w-[80px]",
    classesChildren: "",
    keyValue: "truckNames",
    inlineSize: 150,
    isHidden: false,
  },
  {
    caption: "Trailer",
    keySize: "dispatch_tbl_doors_trailer_name",
    classes: "w-[80px]",
    classesChildren: "",
    keyValue: "trailerNames",
    inlineSize: 150,
    isHidden: false,
  },
  {
    caption: "terminal address",
    keySize: "dispatch_tbl_doors_terminal_address1",
    classes: "capitalize min-w-[170px] w-[170px]",
    classesChildren: "",
    keyValue: "terminalAddress",
    inlineSize: 200,
    isHidden: true,
  },
  {
    caption: "creation date",
    keySize: "dispatch_tbl_doors_creation_date",
    classes: "min-w-[135px] w-[135px]",
    classesChildren: "",
    keyValue: "creationDate",
    inlineSize: 170,
    isHidden: true,
  },
]

const COLUMNS_CARRIERS: Column[] = [
  {
    caption: "ID",
    keySize: "dispatch_tbl_carriers_id",
    field: "id",
    keyValue: "id",
    inlineSize: 60,
    isHidden: false,
  },
  {
    caption: "Name",
    keySize: "dispatch_tbl_carriers_name",
    field: "name",
    keyValue: "name",
    inlineSize: 150,
    isHidden: false,
  },
  {
    caption: "Email",
    keySize: "dispatch_tbl_carriers_email",
    field: "email",
    keyValue: "email",
    inlineSize: 170,
    isHidden: false,
  },
  {
    caption: "MC Number",
    keySize: "dispatch_tbl_carriers_mc_number",
    field: "mc_number",
    keyValue: "mc_number",
    inlineSize: 120,
    isHidden: false,
  },
  {
    caption: "Phone",
    keySize: "dispatch_tbl_carriers_phone",
    field: "phone",
    keyValue: "phone",
    inlineSize: 120,
    isHidden: false,
  },
  {
    caption: "Address 1",
    keySize: "dispatch_tbl_carriers_address_1",
    field: "address_1",
    keyValue: "address_1",
    inlineSize: 350,
    isHidden: false,
  },
  {
    caption: "Address 2",
    keySize: "dispatch_tbl_carriers_address_2",
    field: "address_2",
    keyValue: "address_2",
    inlineSize: 350,
    isHidden: true,
  },
  {
    caption: "Country",
    keySize: "dispatch_tbl_carriers_country",
    field: "country",
    keyValue: "country",
    inlineSize: 100,
    isHidden: true,
  },
  {
    caption: "City",
    keySize: "dispatch_tbl_carriers_city",
    field: "city",
    keyValue: "city",
    inlineSize: 100,
    isHidden: false,
  },
  {
    caption: "State",
    keySize: "dispatch_tbl_carriers_state",
    field: "state",
    keyValue: "state",
    inlineSize: 100,
    isHidden: false,
  },
  {
    caption: "Zip Code",
    keySize: "dispatch_tbl_carriers_zip_code",
    field: "zip_code",
    keyValue: "zip_code",
    inlineSize: 100,
    isHidden: false,
  },
  {
    caption: "Website",
    keySize: "dispatch_tbl_carriers_website",
    field: "website",
    keyValue: "website",
    inlineSize: 170,
    isHidden: true,
  },
  {
    caption: "Contact Name",
    keySize: "dispatch_tbl_carriers_contact_name",
    field: "contact_name",
    keyValue: "contact_name",
    inlineSize: 170,
    isHidden: true,
  },
  {
    caption: "Contact Email",
    keySize: "dispatch_tbl_carriers_contact_email",
    field: "contact_email",
    keyValue: "contact_email",
    inlineSize: 170,
    isHidden: true,
  },
  {
    caption: "Status",
    keySize: "dispatch_tbl_carriers_status_name",
    field: "status_name",
    keyValue: "status_name",
    inlineSize: 120,
    isHidden: false,
  },
  {
    caption: "creator name",
    keySize: "dispatch_tbl_carriers_created_by_name",
    field: "created_by_name",
    keyValue: "created_by_name",
    inlineSize: 120,
    isHidden: true,
  },
  {
    caption: "creator email",
    keySize: "dispatch_tbl_carriers_created_by_email",
    field: "created_by_email",
    keyValue: "created_by_email",
    inlineSize: 130,
    isHidden: true,
  },
]

const COLUMNS_EQUIPMENTS: Column[] = [
  {
    caption: "ID",
    keySize: "dispatch_tbl_equipments_id",
    field: "id",
    keyValue: "id",
    inlineSize: 150,
    isHidden: false,
  },
  {
    caption: "Equipment ID",
    keySize: "dispatch_tbl_equipments_equipment_id",
    field: "equipment_id",
    keyValue: "equipment_id",
    inlineSize: 150,
    isHidden: false,
  },
  {
    caption: "Current Zone ID",
    keySize: "dispatch_tbl_equipments_current_zone_id",
    keyValue: "current_zone_id",
    inlineSize: 150,
    isHidden: false,
  },
  {
    caption: "Current Zone Code",
    keySize: "dispatch_tbl_equipments_current_zone_code",
    keyValue: "current_zone_code",
    inlineSize: 150,
    isHidden: false,
  },
  {
    caption: "Pictures",
    keySize: "dispatch_tbl_equipments_pictures",
    field: "pictures",
    keyValue: "buttonPictures",
    inlineSize: 100,
    isHidden: false,
  },
  {
    caption: "Plate",
    keySize: "dispatch_tbl_equipments_plate",
    field: "plate",
    keyValue: "plate",
    inlineSize: 150,
    isHidden: false,
  },
  {
    caption: "Model",
    keySize: "dispatch_tbl_equipments_model",
    field: "model",
    keyValue: "model",
    inlineSize: 150,
    isHidden: false,
  },
  {
    caption: "Make",
    keySize: "dispatch_tbl_equipments_make",
    field: "make",
    keyValue: "make",
    inlineSize: 150,
    isHidden: false,
  },
  {
    caption: "Year",
    keySize: "dispatch_tbl_equipments_year",
    field: "year",
    keyValue: "year",
    inlineSize: 150,
    isHidden: false,
  },
  {
    caption: "Status",
    keySize: "dispatch_tbl_equipments_status",
    field: "status",
    keyValue: "status",
    inlineSize: 120,
    isHidden: false,
  },
  {
    caption: "VIN",
    keySize: "dispatch_tbl_equipments_serial_number",
    field: "serial_number",
    keyValue: "serial_number",
    inlineSize: 110,
    isHidden: false,
  },
  {
    caption: "EQ Class",
    keySize: "dispatch_tbl_equipments_eq_class_name",
    keyValue: "eq_class_name",
    inlineSize: 200,
    isHidden: false,
  },
  {
    caption: "Created By",
    keySize: "dispatch_tbl_equipments_created_by_name",
    keyValue: "created_by_name",
    inlineSize: 200,
    isHidden: true,
  },
  {
    caption: "Updated By",
    keySize: "dispatch_tbl_equipments_updated_by_name",
    keyValue: "updated_by_name",
    inlineSize: 200,
    isHidden: true,
  },
]

export enum TABLE_SIZES_COLUMNS {
  DISPATCH_TRIPS = "DISPATCH_TRIPS",
  DISPATCH_DOORS = "DISPATCH_DOORS",
  DISPATCH_FREIGHTS_BILLS = "DISPATCH_FREIGHTS_BILLS",
  DISPATCH_TRIP_HISTORY = "DISPATCH_STATUS_HISTORY_TRIPS",
  DISPATCH_DRIVERS_HISTORY = "DISPATCH_STATUS_HISTORY_DRIVERS",
  DISPATCH_TRUCKS_HISTORY = "DISPATCH_STATUS_HISTORY_TRUCKS",
  DISPATCH_TRAILERS_HISTORY = "DISPATCH_STATUS_HISTORY_TRAILERS",
  DISPATCH_FREIGHTS_HISTORY = "DISPATCH_STATUS_HISTORY_FREIGHTS",
  DISPATCH_FREIGHTS = "DISPATCH_FREIGHTS",
  DISPATCH_FREIGHTS_PICKUPS = "DISPATCH_FREIGHTS_PICKUPS",
  DISPATCH_FREIGHTS_DELIVERIES = "DISPATCH_FREIGHTS_DELIVERIES",
  DISPATCH_DRIVERS = "DISPATCH_DRIVERS",
  DISPATCH_TRUCKS = "DISPATCH_TRUCKS",
  DISPATCH_TRAILERS = "DISPATCH_TRAILERS",
  DISPATCH_DISPATCHES = "DISPATCH_DISPATCHES",
  DISPATCH_CARRIERS = "DISPATCH_CARRIERS",
  DISPATCH_EQUIPMENTS = "DISPATCH_EQUIPMENTS",
}

export const TABLE_COLUMNS = {
  COLUMNS_LOADS_DELIVERIES,
  COLUMNS_LOADS_PICKUPS,
  COLUMNS_DRIVERS,
  COLUMNS_TRUCKS,
  COLUMNS_TRAILERS,
  COLUMNS_DISPATCHES,
  COLUMNS_FREIGHT_BILLS,
  COLUMNS_STATUS_HISTORY_TRIPS,
  COLUMNS_STATUS_HISTORY_DRIVERS,
  COLUMNS_STATUS_HISTORY_TRUCKS,
  COLUMNS_STATUS_HISTORY_TRAILERS,
  COLUMNS_STATUS_HISTORY_FREIGHTS,
  COLUMNS_TRIPS,
  COLUMNS_DOORS,
  COLUMNS_CARRIERS,
  COLUMNS_EQUIPMENTS,
}

const makeIndexed = (columns: Column[]) => {
  return columns.reduce((acc, item) => {
    const key = item.field
    // @ts-ignore
    if (key) acc[key] = item.caption
    return acc
  }, {})
}

export const CAPTIONS_INDEXED_BY_FIELD = {
  COLUMNS_LOADS: makeIndexed(TABLE_COLUMNS.COLUMNS_LOADS_DELIVERIES),
  COLUMNS_DRIVERS: makeIndexed(TABLE_COLUMNS.COLUMNS_DRIVERS),
  COLUMNS_DISPATCHES: makeIndexed(TABLE_COLUMNS.COLUMNS_DISPATCHES),
  COLUMNS_TRUCKS: makeIndexed(TABLE_COLUMNS.COLUMNS_TRUCKS),
  COLUMNS_TRAILERS: makeIndexed(TABLE_COLUMNS.COLUMNS_TRAILERS),
  COLUMNS_CARRIERS: makeIndexed(TABLE_COLUMNS.COLUMNS_CARRIERS),
  COLUMNS_DOORS: makeIndexed(TABLE_COLUMNS.COLUMNS_DOORS),
  COLUMNS_TRIPS: makeIndexed(TABLE_COLUMNS.COLUMNS_TRIPS),
  COLUMNS_EQUIPMENTS: makeIndexed(TABLE_COLUMNS.COLUMNS_EQUIPMENTS),
}

export const originalFieldsSearch: string[] = [
  "bill_number",
  "statusCode",
  "pallets",
  "weight",
  "charges",
  "origin_name",
  "date_pickup_start",
  "date_pickup_end",
  "origin_address_1",
  "serviceLevel_name",
  "destination_name",
  "date_delivery_start",
  "date_delivery_end",
  "destination_address_1",
  "margin",
]
