import type { FreightDetail, FreightDetailAdapter, FreightStatusHistory, FreightStatusHistoryAdapter, Load, LoadNormalize } from "./LoadType"
import type { ILoadToTable } from "../DispatchPage/DispatchType"
import Format, { formatDate, formatPhoneNumber, formatTime } from "@/utils/formats"
import DispatchAdapter from "../DispatchPage/dispatch.adapter"

export class Adapter {
  static entityToFieldsFromResponse(startWith: "customer" | "origin" | "destination", response: Load) {
    switch (startWith) {
      case "customer":
        return {
          id: response.customer?.id ?? 0,
          name: response.customer_name ?? "",
          address_1: response.customer_address_1 ?? response?.customer?.address_1 ?? "",
          address_2: response.customer_address_2 ?? response?.customer?.address_2 ?? "",
          city: response.customer_city ?? response?.customer?.city ?? "",
          state: response.customer_state ?? response?.customer?.state ?? "",
          zip_code: response.customer_zip_code ?? response.customer?.zip_code ?? "",
        }
      case "destination":
        return {
          id: response.destination?.id ?? 0,
          name: response.destination_name ?? "",
          address_1: response.destination_address_1 ?? response?.destination?.address_1 ?? "",
          address_2: response.destination_address_2 ?? response?.destination?.address_2 ?? "",
          city: response.destination_city ?? response?.destination?.city ?? "",
          state: response.destination_state ?? response?.destination?.state ?? "",
          zip_code: response.destination_zip_code ?? response?.destination?.zip_code ?? "",
        }
      default:
        return {
          id: response.origin?.id ?? 0,
          name: response.origin_name ?? "",
          address_1: response.origin_address_1 ?? response?.origin?.address_1 ?? "",
          address_2: response.origin_address_2 ?? response?.origin?.address_2 ?? "",
          city: response.origin_city ?? response?.origin?.city ?? "",
          state: response.origin_state ?? response?.origin?.state ?? "",
          zip_code: response.origin_zip_code ?? response?.origin?.zip_code ?? "",
        }
    }
  }
  static normalizeLoad(value: Load): LoadNormalize {
    const pallets = value.freight_details.reduce<number>((acc, item) => acc + Number(item.pallets), 0)
    const weight = value.freight_details.reduce<number>((acc, item) => acc + Number(item.weight), 0)
    return {
      ...value,
      tripNumbers: "tripNumbers",
      billNumber: "billNumber",
      statusName: "statusName",
      serviceLevelName: value.service_level?.name ?? "-",
      commodityName: value.commodity?.name ?? "-",
      pickup: "pickup",
      delivery: "delivery",
      driverNames: "drivers",
      truckNames: "trucks",
      trailerNames: "trailers",
      docs: "docs",
      options: "options",
      createdByName: value.created_by?.name,
      createdByEmail: value.created_by?.email,
      billName: value.bill_to?.name ?? "-",
      billEmail: value.bill_to?.email ?? "-",
      customerEmail: value.customer?.email ?? "-",
      customerPhone: formatPhoneNumber(value.customer?.phone_number ?? "") || "-",
      pallets,
      weight,
      charges: Number(value.charges || "0"),
    }
  }
  static detailToForm(item: FreightDetail): FreightDetailAdapter {
    return {
      ...item,
      commodity_name: item.commodity?.name ?? "",
      weight_estimate: item.weight_estimate ?? item.weight ?? "",
      pieces_estimate: item.pieces_estimate ?? item.pieces ?? "",
      pallets_estimate: item.pallets_estimate ?? item.pallets ?? "",
      _length_estimate: item._length_estimate ?? item._length ?? "",
      width_estimate: item.width_estimate ?? item.width ?? "",
      height_estimate: item.height_estimate ?? item.height ?? "",
    }
  }
  static loadToLoadWithType(data: Load, tx_type: ILoadToTable["tx_type"]): ILoadToTable {
    return {
      ...DispatchAdapter.LoadsToTable(data),
      tx_type,
    }
  }
  static LoadToTable(load: Load): ILoadToTable {
    const pallets = load.freight_details.reduce<number>((acc, item) => acc + Number(isNaN(Number(item.pallets)) ? "0" : item.pallets), 0)
    const weight = load.freight_details.reduce<number>((acc, item) => acc + Number(isNaN(Number(item.weight)) ? "0" : item.weight), 0)
    const newCharges = Number(load.charges ?? "0")
    return {
      ...load,
      billNumber: load.bill_number,
      statusCode: load.status.status_code,
      statusName: load.status.status_code,
      tripNumbers: load.trip_number?.map((c) => c.trip_number || "-").join(" / ") || "-",
      driverNames: load?.trip_info?.flatMap((c) => c.drivers.map((c) => c.name)).join(","),
      truckNames: load?.trip_info?.flatMap((c) => c.trucks.map((c) => c.name)).join(","),
      trailerNames: load?.trip_info?.flatMap((c) => c.trailers.map((c) => c.name)).join(","),
      originName: String(load.origin_name).toLocaleUpperCase(),
      destinationName: String(load.destination_name).toLocaleUpperCase(),
      date_pickup_start: load.date_pickup_start || "",
      date_pickup_end: load.date_pickup_end || "",
      date_delivery_start: load.date_delivery_start || "",
      date_delivery_end: load.date_delivery_end || "",
      date_pickup_start_to_string_short: Format.dateToStringShort(load.date_pickup_start || ""),
      date_pickup_end_to_string_short: Format.dateToStringShort(load.date_pickup_end || ""),
      date_delivery_start_to_string_short: Format.dateToStringShort(load.date_delivery_start || ""),
      date_delivery_end_to_string_short: Format.dateToStringShort(load.date_delivery_end || ""),
      locationOrigin: `${load.origin_city ?? ""} - ${load.origin_state ?? ""} ${load.origin_zip_code ?? ""}`,
      locationDestination: `${load.destination_city ?? ""} - ${load.destination_state ?? ""} ${load.destination_zip_code ?? ""}`,
      serviceLevel: load.service_level?.name ?? "-",
      pickup_lat: Number(load.pickup_latitude) || Number(load.origin?.latitude),
      pickup_lng: Number(load.pickup_longitude) || Number(load.origin?.longitude),
      delivery_lat: Number(load.delivery_latitude) || Number(load.destination?.latitude),
      delivery_lng: Number(load.delivery_longitude) || Number(load.destination?.longitude),
      origin: load.origin,
      destination: load.destination,
      trip_info: load.trip_info,
      trip_number: load.trip_number,
      pickupSchedule: `${formatDate(load.date_pickup_start!)} / ${formatTime(load.date_pickup_start!)} - ${formatTime(load.date_pickup_end!)}`,
      deliverySchedule: `${formatDate(load.date_delivery_start!)} / ${formatTime(load.date_delivery_start!)} - ${formatTime(load.date_delivery_end!)}`,
      charges: isNaN(newCharges) ? 0 : newCharges,
      pallets,
      weight,
      createdByName: load.created_by?.name ?? "-",
      createdByEmail: load.created_by?.email ?? "-",
      billName: load.bill_to?.name ?? "-",
      billEmail: load.bill_to?.email ?? "-",
      customerName: load.customer?.name,
      customerEmail: load.customer?.email,
      customerPhone: formatPhoneNumber(load.customer?.phone_number ?? ""),
      margin: load.margin ?? "-",
    }
  }
  static FreightStatusHistoryToTable(data: FreightStatusHistory): FreightStatusHistoryAdapter {
    return {
      ...data,
      status_code: data.status_code ?? "",
      trip_id: data.trip_id || 0,
      comments: data.comments || "-",
      updated_by_name: data.updated_by?.name || "-",
      date_change_to_date: data.date_change ? new Date(data.date_change) : new Date(),
      date_change: data.date_change || "-",
    }
  }
}
