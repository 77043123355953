import { useContext, useEffect, useRef, useState } from "react"
import { useIntl } from "react-intl"
import { Draggable, Droppable } from "react-beautiful-dnd"

import type { SectionProps } from "./HideShowColumnsType"
import useClickOutside from "@/hooks/useClickOutside"
import HideShowColumnsRow from "./HideShowColumnsRow"
import { HideShowColumnContext } from "./HideShowColumnsContext"
import { Input } from "../Input"
import { getStyled } from "@/pages/DispatchPage/utils"

const HideShowColumnsSectionColumns = ({ columns = [], registeredColumns = [], onTransfer, disabled = false, title = "", areaName }: SectionProps) => {
  const { formatMessage: $t } = useIntl()
  const { setColumnProcessing, unselectedAll } = useContext(HideShowColumnContext)
  const [selectedColumns, setSelectedColumns] = useState<Column[]>([])
  const [search, setSearch] = useState<string>("")
  const [showMenu, setShowMenu] = useState<boolean>(false)
  const [positionDropDown, setPositionDropDown] = useState({})
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const [localColumnProcessing, setLocalColumnProcessing] = useState<Column | undefined>()

  const handlerSelected = (ev: React.MouseEvent<HTMLDivElement>, column?: Column) => {
    ev.preventDefault()
    ev.stopPropagation()
    if (column) {
      if (ev.ctrlKey) {
        const existsInList = selectedColumns.findIndex((c) => c.keyValue === column.keyValue)
        if (existsInList === -1) {
          setSelectedColumns([...selectedColumns, column])
        } else {
          setSelectedColumns((prev) => {
            const newColumns = [...prev]
            newColumns.splice(existsInList, 1)
            return newColumns
          })
        }
      } else {
        setSelectedColumns([column])
      }
    } else {
      setSelectedColumns([])
    }
  }

  const handleContextMenu = (ev: any, column?: Column) => {
    ev.preventDefault()
    setLocalColumnProcessing(column)
    const { clientX, clientY } = ev
    setShowMenu(true)
    setPositionDropDown(() => ({
      left: `${clientX}px`,
      top: `${clientY}px`,
    }))
  }

  useEffect(() => {
    onTransfer(selectedColumns)
  }, [selectedColumns])

  useClickOutside(dropdownRef, () => {
    if (showMenu) setShowMenu(false)
  })

  const handleEditClick = () => {
    setShowMenu(false)
    if (localColumnProcessing) {
      unselectedAll()
      setColumnProcessing({ ...localColumnProcessing })
    }
  }

  const columnsFiltered = columns.filter((column) => column.keyValue.includes(search))

  return (
    <div className="col-span-5 sm:col-span-4">
      {showMenu && (
        <div
          ref={dropdownRef}
          className="fixed min-w-max rounded shadow-lg bg-white dark:bg-slate-800 overflow-hidden ring-opacity-5 z-10 border dark:border-slate-700"
          style={{ height: "auto", ...positionDropDown }}
        >
          <button
            type="button"
            onClick={handleEditClick}
            className="w-full flex items-center gap-1 ps-4 pe-8 py-1 text-xs dark:text-slate-300 hover:bg-gray-50 dark:hover:bg-slate-800"
          >
            {$t({ id: "edit caption" })}
          </button>
        </div>
      )}
      <div>
        <div>
          <p className="text-slate-600 dark:text-slate-300">{title}</p>
        </div>
        <div className="mb-1">
          <Input
            value={search}
            onChange={(ev) => setSearch(String(ev.target.value).toLocaleLowerCase())}
            placeholder={`${$t({ id: "Search" })} ${$t({ id: "By" })} ${$t({ id: "Caption" })}`}
          />
        </div>
        <div className="border dark:border-slate-700 h-80 overflow-auto relative">
          <HideShowColumnsRow isHeader caption={$t({ id: "Caption" })} field={$t({ id: "Field" })} />
          <Droppable droppableId={areaName} direction="vertical">
            {(provided) => (
              <div ref={provided.innerRef} className={`min-h-[calc(100%-23.09px)]`} {...provided.droppableProps}>
                {columnsFiltered.map((column, index) => (
                  <Draggable key={index} draggableId={`${areaName}_${index}`} {...provided.droppableProps} index={index} isDragDisabled={disabled}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        className="relative"
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getStyled(snapshot, provided)}
                      >
                        <HideShowColumnsRow
                          key={index}
                          caption={column.caption}
                          field={column.keyValue}
                          active={registeredColumns.some((c) => c.keyValue === column.keyValue)}
                          onClick={(ev) => handlerSelected(ev, column)}
                          onContextMenu={(ev) => handleContextMenu(ev, column)}
                          disabled={disabled}
                          isHover
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </div>
  )
}
export default HideShowColumnsSectionColumns
