import Tailwindcss from "@/utils/tailwind"
import clsx from "clsx"
import { MouseEvent } from "react"
import { FaArrowDown, FaArrowUp } from "react-icons/fa"

interface Props {
  value: string
  filterBy: string
  field: string
  children: React.ReactNode
  handleChange(newValue?: EmitValuesSort): void
  classesWrapperChildren?: string
}

type TSort = "asc" | "desc"

export const ArrowSort = ({ value, children, filterBy, field, handleChange, classesWrapperChildren = "" }: Props) => {
  const handleClick = (sort: TSort) => {
    if (field == filterBy && value == sort) {
      handleChange({
        field: "id",
        sort: "asc",
      })
    } else {
      handleChange({ field, sort })
    }
  }
  const handleSortArrowClick = (ev: MouseEvent<SVGElement>, sort: TSort) => {
    ev.preventDefault()
    ev.stopPropagation()
    if (field == filterBy && sortBy !== sort) return
    handleChange({ field, sort })
  }

  const sortBy = value === "asc" ? "desc" : "asc"

  const arrowUpClasses = Tailwindcss.mergeClasses(
    "text-[8px] dark:text-slate-500 hover:text-blue-700 dark:hover:text-blue-500",
    filterBy === field && value === "asc" ? "text-blue-700 dark:!text-blue-500" : "",
  )
  const arrowDownClasses = Tailwindcss.mergeClasses(
    "text-[8px] dark:text-slate-500 hover:text-blue-700 dark:hover:text-blue-500",
    filterBy === field && value === "desc" ? "text-blue-700 dark:!text-blue-500" : "",
  )

  return (
    <button
      type="button"
      className="flex gap-1 items-center cursor-pointer select-none"
      onClick={(ev) => {
        ev.stopPropagation()
        ev.preventDefault()
        handleClick(sortBy)
      }}
    >
      <span className="flex flex-col gap-0.5">
        <FaArrowUp className={arrowUpClasses} onClick={(ev) => handleSortArrowClick(ev, "asc")} />
        <FaArrowDown className={arrowDownClasses} onClick={(ev) => handleSortArrowClick(ev, "desc")} />
      </span>
      <span className={clsx("hover:text-blue-700 dark:hover:text-blue-500", classesWrapperChildren)}>{children}</span>
    </button>
  )
}
