import { MouseEvent, useContext, useEffect, useRef, useState } from "react"
import {
  TbLogout,
  TbUserEdit,
  TbLayoutSidebarLeftCollapseFilled,
  TbLayoutSidebarLeftExpand,
  TbBellFilled,
  TbBellOff,
  TbMoonFilled,
  TbMoon,
  TbPinnedFilled,
  TbEdit,
} from "react-icons/tb"
import { HiXCircle } from "react-icons/hi"
import { useLocation, useNavigate } from "react-router-dom"
import { useIntl } from "react-intl"

import type { TVisitedPage } from "@/types/App"
import { LayoutContext } from "@/context/AppAuthContext"
import { AuthenticationContext } from "@/context/AuthenticateContext"
import useClickOutside from "@/hooks/useClickOutside"
import { DropdownLocale } from "@/@core/DropdownLocale"
import { Button, Dropdown, Modal, Panel } from "@/@core"
import { SocketContext } from "@/context/SocketContext"
import ChatIndex from "@/components/Chat/ChatIndex"
import ChatAvatar from "@/components/Chat/ChatAvatar"
import { TranslateContext } from "@/context/TranslateContext"
import { AppContext } from "@/context/AppContext"
import { CompanyForm } from "@/pages/Company/CompanyFormPage"
import clsx from "clsx"

const limitTotalMessages = 99

export const AppLayoutHeader = () => {
  const { notificationsCount, driversWithMessages, onlineUsers, selectNotification } = useContext(SocketContext)
  const { broken, setToggled, _setCollapsed, collapsed } = useContext(LayoutContext)!
  const { setAuth, logout, Auth: authenticate } = useContext(AuthenticationContext)!
  const { isDark, toggleMode } = useContext(TranslateContext)
  const { storeApp } = useContext(AppContext)
  const { visitedPages } = storeApp
  const navigation = useNavigate()
  const { formatMessage: $t } = useIntl()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [showProfile, setShowProfile] = useState<boolean>(false)
  const [showCompanyForm, setShowCompanyForm] = useState<boolean>(false)
  const location = useLocation()

  const userOptionsRef = useRef<HTMLDivElement | null>(null)

  const [Auth] = useState(() => {
    try {
      return JSON.parse(localStorage.user ?? "{}")
    } catch (error) {
      return {}
    }
  })

  const openCompanyForm = () => setShowCompanyForm(true)
  const closeCompanyForm = () => setShowCompanyForm(false)

  useClickOutside(userOptionsRef, () => isOpen && setIsOpen(false))

  useEffect(() => {
    if (!Object.keys(Auth).length) handleLogoutClick()
  }, [Auth])

  const handleLogoutClick = () => {
    logout()
    setIsOpen(false)
    navigation("/sign-in")
  }

  const numberNotifications = notificationsCount?.reduce((a, b) => a + b.count, 0) ?? 0
  const notifications = ([...driversWithMessages].filter((c) => Boolean(c.unreadCount)) || []).sort(
    // @ts-ignore
    (a, b) => new Date(b.lastMessage.send_at.date) - new Date(a.lastMessage.send_at.date),
  )

  const renderMessageFormat = (message: string) => {
    message = message.replace(/(https?:\/\/[^\s]+)/g, `<i style="font-style: italic;">$1</i>`)
    return message
  }

  const onToggleTheme = () => {
    toggleMode()
    setIsOpen(false)
  }

  const onUpdateDataHandler = (company: Company) => {
    const newUser = { ...authenticate }
    newUser.company = {
      ...authenticate.company,
      ...company,
      // @ts-ignore
      full_company_logo_path: company.company_logo,
    }
    localStorage.user = JSON.stringify(newUser)
    setAuth(newUser)
  }

  return (
    <>
      <Modal open={showProfile} size="xxs" title={$t({ id: "Profile" })} onClose={() => setShowProfile(false)}>
        <div className="p-3">
          <p className="text-base font-bold">{authenticate.terminal?.name}</p>
          <p className="text-blue-600 font-semibold mb-2">{authenticate.role.description}</p>
          <div className="flex gap-2 relative mb-6">
            <ChatAvatar name={String(authenticate.name)} size="large" active />
            <div>
              <p>{authenticate.name}</p>
              <p className="text-xs">{authenticate.email}</p>
            </div>
          </div>
          <p className="text-green-600 font-semibold mb-2">{$t({ id: "Company" })}</p>
          <div className="mb-6">
            <div className="flex gap-2">
              {!!authenticate.company.full_company_logo_path && (
                <img className="w-10 h-10 min-w-10 min-h-10 rounded-full border" src={authenticate.company.full_company_logo_path} alt="Name " />
              )}
              <div>
                <p className="uppercase">
                  <span className="block">{$t({ id: "Company name" })}</span>
                  <span className="italic text-slate-400 ps-3">{authenticate.company?.name}</span>
                </p>
                <p>
                  <span className="block">{$t({ id: "Company email" })}</span>
                  <span className="italic text-slate-400 ps-3">{authenticate.company?.email}</span>
                </p>
                <p>
                  <span className="block">{$t({ id: "Company address" })}</span>
                  <span className="block italic text-slate-400 ps-3">{authenticate.company?.address}</span>
                  <span className="block italic text-slate-400 ps-3 uppercase">
                    {authenticate.company.city} {authenticate.company.zip_code} {authenticate.company.country}
                  </span>
                </p>
              </div>
            </div>
            {/* <p>
              <span className="block">{$t({ id: "Company phone" })}</span>
              <span className="block italic text-slate-400 ps-3">{'-'}</span>
            </p> */}
            <div className="mt-4">
              <Button color="primary" className="gap-1.5" icon onClick={openCompanyForm}>
                <TbEdit size={13} /> <span className="text-xs">{$t({ id: "Edit Company" })}</span>
              </Button>
            </div>
          </div>
          <p className="text-slate-400 italic text-xs text-center">
            <span className="font-semibold">{$t({ id: "Created" })}</span>: {new Date(authenticate.created_at).toDateString()}
          </p>
        </div>
      </Modal>
      <Panel open={showCompanyForm} setOpen={setShowCompanyForm} title={$t({ id: "Edit Company" })} onClose={closeCompanyForm}>
        {(onClose) => <CompanyForm isOpen={showCompanyForm} onClose={onClose} onUpdateData={onUpdateDataHandler} />}
      </Panel>

      <div className="bg-slate-100 w-full flex items-center gap-x-1 justify-between p-1 dark:bg-slate-900 sticky top-0 z-50">
        <div className="flex gap-1 items-center max-w-max overflow-auto">
          <div>
            {broken ? (
              <button className="transition-transform active:scale-90 p-1.5" onClick={() => setToggled((prev) => !prev)}>
                {collapsed ? (
                  <TbLayoutSidebarLeftExpand className="text-[22px] text-blue-950 dark:text-slate-500" />
                ) : (
                  <TbLayoutSidebarLeftCollapseFilled className="text-[22px] text-blue-950 dark:text-slate-500" />
                )}
              </button>
            ) : (
              <button className="transition-transform active:scale-90 p-1.5" onClick={() => _setCollapsed((prev) => !prev)}>
                {collapsed ? (
                  <TbLayoutSidebarLeftExpand className="text-[22px] text-blue-950 dark:text-slate-500" />
                ) : (
                  <TbLayoutSidebarLeftCollapseFilled className="text-[22px] text-blue-950 dark:text-slate-500" />
                )}
              </button>
            )}
          </div>
          <div className="flex gap-1 items-center max-w-max overflow-auto">
            {[...visitedPages].reverse().map((item, index) => (
              <ButtonShortcut key={index} title={item.title} pathname={item.pathname} active={buildPathname(item.pathname) === location.pathname} />
            ))}
          </div>
        </div>
        <div className="flex items-center gap-1 min-w-max">
          <DropdownLocale direction="end" />
          {Auth.role.name === "user_admin" && (
            <Dropdown
              renderLabel={
                <div>
                  {!!numberNotifications && (
                    <div className="h-4 w-4 rounded-full bg-red-600 absolute top-[2px] right-[2px] text-[8px] text-white flex items-center justify-center font-serif">
                      {numberNotifications > limitTotalMessages ? `+${limitTotalMessages}` : numberNotifications}
                    </div>
                  )}
                  <TbBellFilled className="text-[20px]" />
                </div>
              }
            >
              {notifications.length ? (
                <>
                  <Dropdown.Item readOnly>
                    <div className="flex gap-2 min-w-full text-white bg-darkblue px-1 py-2">
                      <p className="capitalize">
                        {$t({ id: "notifications" })} ({numberNotifications > limitTotalMessages ? `+${limitTotalMessages}` : numberNotifications})
                      </p>
                    </div>
                  </Dropdown.Item>
                  {notifications.map((notification, index) => (
                    <Dropdown.Item key={index} onClick={() => selectNotification(notification)}>
                      <div className="flex gap-2 w-[260px] max-w-[260px]">
                        <ChatAvatar name={notification.name} active={onlineUsers.some((c) => c.username === notification.name)} />
                        <div className="relative flex-1 overflow-hidden">
                          <p className="text-gray-400 italic">{notification.name!.split(" ").slice(0, 2).join(" ")}</p>
                          {!!notification.lastMessage?.dateSendAtString && (
                            <p className="box-chat-bubble me-3" dangerouslySetInnerHTML={{ __html: renderMessageFormat(notification.lastMessage.message) }}></p>
                          )}
                          <span className="text-gray-400 absolute right-1 top-0 italic text-[10px]">{notification.lastMessage?.dateSendAtString}</span>
                          <span className="text-[9px] font-serif w-4 h-4 bg-red-600 text-white rounded-full flex justify-center items-center absolute right-0 bottom-0">
                            {notification.unreadCount}
                          </span>
                        </div>
                      </div>
                    </Dropdown.Item>
                  ))}
                </>
              ) : (
                <Dropdown.Item readOnly>
                  <div className="w-[200px] max-w-[200px] text-center py-2 text-gray-400">
                    <TbBellOff className="text-lg m-auto" />
                    <p className="mt-1 capitalize">{$t({ id: "no notifications" })}</p>
                  </div>
                </Dropdown.Item>
              )}
            </Dropdown>
          )}
          <div>
            <div className="relative inline-block text-left" ref={userOptionsRef}>
              <div>
                <button
                  type="button"
                  className="h-9 inline-flex justify-end items-center gap-2 w-full rounded-md ring-1 ring-inset ring-gray-300 border-gray-300 shadow-sm px-5 py-1 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 capitalize dark:bg-slate-800 dark:text-slate-300 dark:hover:bg-slate-700 dark:ring-gray-700"
                  id="options-menu"
                  aria-haspopup="true"
                  aria-expanded="true"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <p className="max-w-[80px] sm:max-w-max text-nowrap overflow-hidden text-ellipsis">{String(Auth.name).toLocaleLowerCase()}</p>
                  <ChatAvatar name={String(Auth.name)} size="small" active />
                </button>
              </div>
              {isOpen && (
                <div className="origin-top-right absolute right-0 mt-0.5 min-w-max rounded-md shadow-lg bg-white dark:bg-slate-800 dark:text-slate-300 ring-1 ring-black ring-opacity-5 z-[100]">
                  <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <button
                      type="button"
                      onClick={() => {
                        setIsOpen(false)
                        setShowProfile(true)
                      }}
                      className="w-full flex item-center gap-1 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-700 dark:bg-slate-800 dark:text-slate-300"
                      role="menuitem"
                    >
                      <TbUserEdit className="text-lg" />
                      <span>{$t({ id: "Profile" })}</span>
                    </button>
                    <button
                      type="button"
                      onClick={onToggleTheme}
                      className="w-full flex item-center gap-1 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-700 dark:bg-slate-800 dark:text-slate-300 capitalize"
                      role="menuitem"
                    >
                      {!isDark && <TbMoonFilled className="text-lg" />}
                      {isDark && <TbMoon className="text-lg" />}
                      <span>{$t({ id: !isDark ? "dark mode" : "light mode" })}</span>
                    </button>
                    <hr className="border-gray-300 dark:border-gray-700" />
                    <button
                      type="button"
                      onClick={handleLogoutClick}
                      className="w-full flex item-center gap-1 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-700 dark:bg-slate-800 dark:text-slate-300"
                      role="menuitem"
                    >
                      <TbLogout className="text-lg" />
                      <span>{$t({ id: "Logout" })}</span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ChatIndex />
    </>
  )
}

function buildPathname(pathname: string) {
  if (pathname.startsWith("/")) return pathname
  return `/${pathname}`
}

function ButtonShortcut(visitedPage: TVisitedPage & { active?: boolean }) {
  const { pathname, title, active = false } = visitedPage
  const navigation = useNavigate()
  const { dispatchApp } = useContext(AppContext)

  const onRedirectClick = () => {
    navigation(pathname)
  }

  const removeVisitedPage = (ev: MouseEvent<HTMLDivElement>) => {
    ev.preventDefault()
    ev.stopPropagation()
    dispatchApp({ type: "REMOVE_PAGE_OF_VISITED_PAGES", payload: visitedPage })
  }

  const classNameActive = active ? "text-white bg-blue-800" : "text-gray-500 bg-white"

  return (
    <button
      type="button"
      onClick={onRedirectClick}
      className={clsx(
        "flex group relative uppercase leading-none py-2 max-w-32 w-full px-3 items-center text-xs space-x-2 font-medium text-center border border-gray-200 rounded shadow-sm dark:text-gray-400 dark:bg-gray-800 dark:border-gray-700",
        classNameActive,
      )}
      data-tooltip-id="t4tms-tooltip"
      data-tooltip-content={title}
      data-tooltip-place="bottom"
    >
      <TbPinnedFilled className={clsx("absolute text-base left-1", active ? "text-white" : "text-blue-800 dark:text-blue-500")} />
      <span className="text-nowrap overflow-hidden text-ellipsis max-w-28">{title}</span>
      <div role="button" onClick={removeVisitedPage} className="invisible group-hover:visible dark:bg-gray-800 absolute right-1">
        <HiXCircle className="text-base text-red-600 bg-white rounded-full" />
      </div>
    </button>
  )
}
