import { BrowserRouter } from "react-router-dom"
import ReactDOM from "react-dom/client"
import { Tooltip } from "react-tooltip"
import { App } from "./App"
import "./index.css"
import AuthenticationContextProvider from "@/context/AuthenticateContext"
import TranslateContextProvider from "@/context/TranslateContext"
import "react-tooltip/dist/react-tooltip.css"
import ErrorBoundary from "./ErrorBoundary"
import ErrorFallback from "./ErrorFallback"

ReactDOM.createRoot(document.getElementById("root")!).render(
  <BrowserRouter>
    <TranslateContextProvider>
      <AuthenticationContextProvider>
        <ErrorBoundary fallback={<ErrorFallback error={new Error("Something went wrong")} />}>
          <App />
        </ErrorBoundary>
        <Tooltip id="t4tms-tooltip" className="!text-xs font-serif !bg-slate-800 z-[100]" />
      </AuthenticationContextProvider>
    </TranslateContextProvider>
  </BrowserRouter>,
)
