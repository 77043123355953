import { AxiosResponse } from "axios"
import { debounce } from "lodash"
import { useState } from "react"

import type {
  ResponseDrivers,
  ResponseDriver,
  Driver,
  Form,
  ResponseStatusHistoryDriver,
  DriverLastPosition,
  ResponseDriverLastPosition,
  IDriverInformationResponse,
  IDriverInformation,
  DriverPicture,
  TDriverExpense,
  IResponseExpenses,
} from "../DriverType"
import axios from "@axios"
import Normalize from "@/utils/normalize"

export { default as InitState } from "../DriverInitial.state"

export const useDrivers = (filters: TableFilters = {}) => {
  const [pictures, setPictures] = useState<DriverPicture[]>([])
  const [fetchingPictures, setFetchingPictures] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [drivers, setDrivers] = useState<Driver[]>([])
  const [pagination, setPagination] = useState<Pagination>({
    count: 0,
    current_page: 1,
    per_page: 1,
    total: 1,
    total_pages: 1,
  })
  const [driverExpense, setDriverExpense] = useState<IResponseExpenses["data"]>({})

  const fetchAll = async (params: TableFilters = filters): Promise<void> => {
    if (loading) return
    try {
      setLoading(true)
      const { data: res } = await axios.get<ResponseDrivers>(`drivers`, { params: Normalize.removePropertiesWithEmptyString(params) })
      const { status, data, pagination } = res
      if (status) {
        setPagination(pagination)
        setDrivers(data)
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const debounceFetchAll = debounce(fetchAll, 350)

  const store = async (payload: Form): Promise<AxiosResponse<ResponseDriver>> => {
    return await axios.post<ResponseDriver>(`drivers`, payload)
  }

  const update = async (driverId: number, payload: Form): Promise<AxiosResponse<ResponseDriver>> => {
    return await axios.put<ResponseDriver>(`drivers/${driverId}`, payload)
  }

  const destroy = async (driverId: number) => {
    try {
      setLoading(true)
      const { data: res } = await axios.delete<ResponseDelete>(`drivers/${driverId}`)
      const { status, msg } = res
      if (!status) throw new Error(msg)
      return null
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  const fetchStatusHistory = async (driverId?: number, queryParams?: Record<string, any>): Promise<AxiosResponse<ResponseStatusHistoryDriver>> => {
    let uri = "driver_status"
    if (driverId) uri += `/${driverId}`
    return await axios.get<ResponseStatusHistoryDriver>(uri, {
      params: Normalize.removePropertiesWithEmptyString(queryParams),
    })
  }

  const getLastPosition = async (driverId: ModelID): Promise<DriverLastPosition> => {
    try {
      setLoading(true)
      const { data: res } = await axios.get<ResponseDriverLastPosition>(`get-last-position/${driverId}`)
      return res.data
    } catch (error) {
      throw new Error("Error get last position of the driver")
    } finally {
      setLoading(false)
    }
  }

  const getAllDriversInformation = async (params: { state?: string } = {}): Promise<IDriverInformation[]> => {
    try {
      const response = await axios.get<IDriverInformationResponse>(`/current-drivers-info`, { params })
      const { status, data } = response.data
      if (status) return data.map((c) => ({ ...c, tx_type: "Drivers" }))
      return []
    } catch (error) {
      return []
    }
  }

  // 👉 Pictures
  const fetchDriverPictures = async (driverId: ModelID): Promise<DriverPicture[]> => {
    try {
      setFetchingPictures(true)
      const response = await axios.get<TResponseBase<DriverPicture[]>>(`list-driver-files/${driverId}`)
      const { status, data } = response.data
      if (status) setPictures(data)
      return data || []
    } catch (error) {
      setPictures([])
      throw error
    } finally {
      setFetchingPictures(false)
    }
  }

  const saveDriverPicture = async (payload: FormData): Promise<DriverPicture[]> => {
    try {
      const response = await axios.post<TResponseBase<DriverPicture[]>>(`upload-pictures-drivers`, payload)
      const { status, data } = response.data
      if (status) {
        setPictures(data)
        return data
      }
      throw new Error("Error saving driver picture")
    } catch (error) {
      throw error
    }
  }

  const destroyDriverPicture = async (payload: any): Promise<AxiosResponse<TResponseBase<DriverPicture[]>>> => {
    try {
      const response = await axios.post<TResponseBase<DriverPicture[]>>(`remove-pictures-drivers`, payload)
      const { status, data } = response.data
      if (status) setPictures(data)
      return response
    } catch (error) {
      throw error
    }
  }

  // 👉 Expenses
  const fetchDriverExpenses = async (payload: TDriverExpense): Promise<IResponseExpenses["data"]> => {
    try {
      setLoading(true)
      const response = await axios.post<IResponseExpenses>(`/get_payment_amount`, payload)
      const { status, data } = response.data
      if (status) {
        setDriverExpense(data)
        return data
      }
      setDriverExpense({})
      throw response
    } catch (error) {
      setDriverExpense({})
      throw error
    } finally {
      setLoading(false)
    }
  }

  return {
    setDrivers,
    drivers,
    setLoading,
    loading,
    fetchAll,
    debounceFetchAll,
    store,
    destroy,
    update,
    pagination,
    fetchStatusHistory,
    getLastPosition,
    getAllDriversInformation,
    // 👉 Pictures
    destroyDriverPicture,
    fetchDriverPictures,
    saveDriverPicture,
    setFetchingPictures,
    fetchingPictures,
    setPictures,
    pictures,
    // 👉 Expense
    fetchDriverExpenses,
    setDriverExpense,
    driverExpense,
  }
}
