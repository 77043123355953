// 👉 Types
import type { TDispatchMapReducer, TLoadReducer, TDispatchReducer, TAppReducer, TColumnReducer } from "@/store/AppTypes"
// 👉 Actions
import { dispatchMapActions } from "@pages/DispatchMap/context/DispatchMapActionsTypes"
import { loadActionsTypes } from "@/pages/Loads/context/LoadActionsTypes"
import { dispatchActionsTypes } from "@/pages/DispatchPage/context/DispatchActionsTypes"
import { appActionsTypes } from "@/store/reducers/AppActionsTypes"
import { columnActionsTypes } from "@/store/reducers/ColumnActionsType"

// 👉 Reducers
export const dispatchMapReducer: TDispatchMapReducer = (state, action) => dispatchMapActions[action.type](state, action.payload)
export const loadReducer: TLoadReducer = (state, action) => loadActionsTypes[action.type](state, action.payload)
export const dispatchReducer: TDispatchReducer = (state, action) => dispatchActionsTypes[action.type](state, action.payload)
export const appReducer: TAppReducer = (state, action) => appActionsTypes[action.type](state, action.payload)
// 👉 ColumnContext Reducers
export const columnReducer: TColumnReducer = (state, action) => columnActionsTypes[action.type](state, action.payload)
