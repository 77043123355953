import Axios, { AxiosResponse, InternalAxiosRequestConfig, AxiosError } from "axios"
import Cookies from "js-cookie"

import { API_BASE_URL, KEY_TOKEN, LOCALE } from "@/constants"

const t4SurveyHttpClient = Axios.create({ baseURL: `${API_BASE_URL}/t4survey` })

// 👉 Response
const errorRequestHandler = (error: AxiosError) => Promise.reject(error)
const successRequestHandler = (config: InternalAxiosRequestConfig) => {
  const token = localStorage.getItem(KEY_TOKEN)
  if (token) config.headers.Authorization = `Bearer ${token}`
  config.headers["Accept-Language"] = String(Cookies.get(LOCALE) ?? "EN").toLowerCase()
  return config
}
t4SurveyHttpClient.interceptors.request.use(successRequestHandler, errorRequestHandler)

// 👉 Response
const errorResponseHandler = (error: AxiosError) => Promise.reject(error)
const successResponseHandler = (response: AxiosResponse) => response
t4SurveyHttpClient.interceptors.response.use(successResponseHandler, errorResponseHandler)

export default t4SurveyHttpClient
