import { useState } from "react"
import type { Form, ResponseTruck, ResponseTrucks, Truck, ResponseStatusHistory, TruckPicture } from "@/pages/Trucks/TruckType"
import axios from "@axios"
import { AxiosResponse } from "axios"
import { debounce } from "lodash"
import Normalize from "@/utils/normalize"

export { default as InitState } from "../TruckInitial.state"

export const useTrucks = (filters: TableFilters = {}) => {
  const [pictures, setPictures] = useState<TruckPicture[]>([])
  const [fetchingPictures, setFetchingPictures] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [trucks, setTrucks] = useState<Truck[]>([])
  const [pagination, setPagination] = useState<Pagination>({} as Pagination)

  const fetchAll = async (params: TableFilters = filters): Promise<void> => {
    try {
      setLoading(true)
      const { data: res } = await axios.get<ResponseTrucks>(`truck`, { params: Normalize.removePropertiesWithEmptyString(params) })
      const { status, data, pagination } = res
      if (status) {
        setPagination(pagination)
        setTrucks(data)
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const debounceFetchAll = debounce(fetchAll, 350)

  const store = async (payload: Form): Promise<AxiosResponse<ResponseTruck>> => {
    return await axios.post<ResponseTruck>(`truck`, payload)
  }

  const update = async (truckId: number, payload: Form): Promise<AxiosResponse<ResponseTruck>> => {
    return await axios.put<ResponseTruck>(`truck/${truckId}`, payload)
  }

  const destroy = async (truckId: number) => {
    try {
      setLoading(true)
      const { data: res } = await axios.delete<ResponseDelete>(`truck/${truckId}`)
      const { status, msg } = res
      if (!status) throw new Error(msg)
      return null
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  const fetchStatusHistory = async (driverId?: number, queryParams?: Record<string, any>): Promise<AxiosResponse<ResponseStatusHistory>> => {
    let uri = "truck_status"
    if (driverId) uri += `/${driverId}`
    return await axios.get<ResponseStatusHistory>(uri, {
      params: Normalize.removePropertiesWithEmptyString(queryParams),
    })
  }

  // 👉 Pictures
  const fetchTruckPictures = async (truckId: ModelID): Promise<TruckPicture[]> => {
    try {
      setFetchingPictures(true)
      const { data: res } = await axios.get<TResponseBase<TruckPicture[]>>(`list-truck-files/${truckId}`)
      const { status, data } = res
      if (status) setPictures(data)
      return data || []
    } catch (error) {
      setPictures([])
      throw error
    } finally {
      setFetchingPictures(false)
    }
  }

  const saveTruckPicture = async (payload: FormData): Promise<TruckPicture[]> => {
    try {
      const response = await axios.post<TResponseBase<TruckPicture[]>>(`upload-pictures-trucks`, payload)
      const { status, data } = response.data
      if (status) {
        setPictures(data)
        return data
      }
      throw new Error("Error saving truck picture")
    } catch (error) {
      throw error
    }
  }

  const destroyTruckPicture = async (payload: any): Promise<AxiosResponse<TResponseBase<TruckPicture[]>>> => {
    try {
      const response = await axios.post<TResponseBase<TruckPicture[]>>(`remove-pictures-trucks`, payload)
      const { status, data } = response.data
      if (status) setPictures(data)
      return response
    } catch (error) {
      throw error
    }
  }

  return {
    debounceFetchAll,
    setTrucks,
    trucks,
    setLoading,
    loading,
    fetchAll,
    store,
    destroy,
    update,
    pagination,
    fetchStatusHistory,
    // 👉 Pictures
    fetchTruckPictures,
    pictures,
    setPictures,
    fetchingPictures,
    setFetchingPictures,
    saveTruckPicture,
    destroyTruckPicture,
  }
}
