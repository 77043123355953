import type { TColumnActionsTypes } from "@/types/Column"

export const columnActionsTypes: TColumnActionsTypes = {
  SET_COLUMNS(state, payload) {
    const { key, columns } = payload
    return {
      ...state,
      [key]: columns,
    }
  },
}
