import {
  useState,
  MouseEvent,
  useEffect,
  useRef,
  DetailedHTMLProps,
  TdHTMLAttributes,
  createContext,
  useContext,
  MutableRefObject,
  HTMLAttributes,
  forwardRef,
  ForwardedRef,
  useImperativeHandle,
  CSSProperties,
} from "react"
import { AiOutlineColumnWidth } from "react-icons/ai"
import { debounce, DebouncedFunc } from "lodash"
import Tailwindcss from "@/utils/tailwind"
import clsx from "clsx"

interface TableResizableContext {
  onChangingTheSizeOfTheirChildren: DebouncedFunc<() => void>
  currentCellElement: MutableRefObject<HTMLTableCellElement | null>
}

export interface IForwardedTableResizableRef {
  getTheSizeOfTheirChildren(returnType?: "object" | "array"): Record<string, number>[] | Record<string, number>
}

const TableResizableContext = createContext({} as TableResizableContext)

interface ITableResizable extends DetailedHTMLProps<HTMLAttributes<HTMLTableElement>, HTMLTableElement> {
  children: React.ReactNode
  onUpdateSizeChildColumns?(payload: Record<string, number>[] | Record<string, number>): void
  onUpdateBlockSize?(newBlockSize: number): void
  minBlockSize?: string
  className?: string
  style?: CSSProperties
  resizable?: boolean
}

const TableResizable = forwardRef(
  (
    { onUpdateSizeChildColumns, onUpdateBlockSize, children, minBlockSize, className = "", style = {}, resizable = true, ...props }: ITableResizable,
    containerRef: ForwardedRef<IForwardedTableResizableRef>,
  ) => {
    const tableRef = useRef<HTMLTableElement | null>(null)
    const currentCellElement = useRef<HTMLTableCellElement | null>(null)

    const __getTheSizeOfTheirChildren__ = (returnType: "object" | "array" = "object"): Record<string, number>[] | Record<string, number> => {
      const trs: NodeListOf<HTMLTableCellElement> = tableRef.current!.querySelectorAll("thead > tr > th")!
      if (!trs.length) return {}
      if (returnType === "array") {
        return Array.from(trs).map<Record<string, number>>((c, index) => ({ [`${c.dataset.inlineSize ?? index}`]: c.getBoundingClientRect().width }))
      } else {
        return Array.from(trs).reduce((acc, c, index) => {
          const key = `${c.dataset?.inlineSize ?? index}`

          // @ts-ignore
          if (!acc[key]) {
            const newInlineSize = String(c.style.maxInlineSize).replace("px", "")
            // @ts-ignore
            // acc[key] = c.getBoundingClientRect().width
            acc[key] = Number(newInlineSize)
          }
          return acc
        }, {})
      }
    }

    const onChangingTheSizeOfTheirChildren = debounce(() => {
      // if (!tableRef.current) return
      if (!!onUpdateSizeChildColumns) onUpdateSizeChildColumns(__getTheSizeOfTheirChildren__())
    }, 800)

    useImperativeHandle(containerRef, () => ({
      getTheSizeOfTheirChildren: (returnType) => __getTheSizeOfTheirChildren__(returnType),
    }))

    const share: TableResizableContext = {
      onChangingTheSizeOfTheirChildren,
      currentCellElement,
    }

    const onChangeBlockSize = debounce((newBlockSize) => {
      if (onUpdateBlockSize) {
        onUpdateBlockSize(newBlockSize)
        isResizingManually.current = false
      }
    }, 1000)
    const tableWrapperRef = useRef<HTMLTableElement>(null)
    const isResizingManually = useRef<boolean>(false)
    const previousHeight = useRef<number | null>(null)

    useEffect(() => {
      const ro = new ResizeObserver((entries) => {
        if (!isResizingManually.current) return
        const [entry] = entries
        onChangeBlockSize.cancel()
        if (!previousHeight.current !== null && entry.contentRect.height !== previousHeight.current) {
          onChangeBlockSize(entry.contentRect.height < 100 ? 100 : entry.contentRect.height)
        }
        previousHeight.current = entry.contentRect.height
      })
      if (tableWrapperRef.current) ro.observe(tableWrapperRef.current)
      return () => {
        if (tableWrapperRef.current) ro.unobserve(tableWrapperRef.current)
      }
    }, [])

    const handleMouseDown = () => (isResizingManually.current = true)
    const handleMouseUp = () => (isResizingManually.current = false)

    return (
      <TableResizableContext.Provider value={share}>
        <div
          ref={tableWrapperRef}
          className={clsx(resizable && "resize-y", className, minBlockSize ? `min-h-[${minBlockSize}px]` : "", "max-w-max overflow-auto mb-0.5 border-b")}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          style={style}
        >
          <table ref={tableRef} {...props}>
            {children}
          </table>
        </div>
      </TableResizableContext.Provider>
    )
  },
)

interface IResizableTHProps {
  children?: React.ReactNode
  initialInlineSize?: number
  keySize?: string
  noResize?: boolean
  ignoreMinSize?: boolean
}

export const ResizableTH = ({ children, initialInlineSize = 125, keySize, noResize, ignoreMinSize = false }: IResizableTHProps) => {
  const { onChangingTheSizeOfTheirChildren, currentCellElement } = useContext(TableResizableContext)
  const [width, setWidth] = useState<number>(initialInlineSize)
  const [dragging, setDragging] = useState<boolean>(false)
  const [x, setX] = useState<number>(0)
  const thRef = useRef<HTMLTableCellElement | null>(null)
  const initialInlineSizeRef = useRef<number>()

  useEffect(() => {
    if (initialInlineSize != width) {
      setWidth(initialInlineSize)
    }
  }, [initialInlineSize])

  const handleMouseDown = (e: MouseEvent<HTMLTableCellElement>) => {
    currentCellElement.current = thRef.current
    initialInlineSizeRef.current = Math.ceil(thRef.current!.getBoundingClientRect().width)
    setDragging(true)
    setX(e.clientX)
  }

  const handleMouseMove = (e: MouseEvent<Document>) => {
    if (dragging) {
      const diff = e.clientX - x
      const newWidth = width + diff
      if (newWidth) {
        setWidth(width + diff)
        setX(e.clientX)
      }
    }
  }

  const handleMouseUp = () => {
    setDragging(false)
    onChangingTheSizeOfTheirChildren.cancel()
    if (thRef.current) {
      if (thRef.current.isEqualNode(currentCellElement.current)) {
        onChangingTheSizeOfTheirChildren()
        currentCellElement.current = null
      }
    }
    initialInlineSizeRef.current = undefined
  }

  useEffect(() => {
    if (!noResize) {
      // @ts-ignore
      document.addEventListener("mousemove", handleMouseMove)
      document.addEventListener("mouseup", handleMouseUp)
    }
    return () => {
      // @ts-ignore
      document.removeEventListener("mousemove", handleMouseMove)
      document.removeEventListener("mouseup", handleMouseUp)
    }
  }, [dragging, width, x, noResize])

  const cssClassNameIcon = Tailwindcss.mergeClasses(
    "text-base cursor-col-resize opacity-0 group-hover/th:opacity-100 text-blue-700 dark:text-slate-400",
    dragging ? "!opacity-100" : "group-hover/th:!opacity-100",
  )

  const dynamicProperties: Record<any, any> = {}
  if (keySize) dynamicProperties["data-inline-size"] = keySize

  return (
    <th
      ref={thRef}
      className="text-xs text-start border dark:border-slate-700 group/th relative select-none p-1 bg-gray-100 dark:bg-slate-800 text-slate-600 dark:text-slate-400 text-nowrap overflow-hidden text-ellipsis"
      style={{
        inlineSize: `${!ignoreMinSize ? (width < 50 ? 50 : width) : width}px`,
        minInlineSize: `${!ignoreMinSize ? (width < 50 ? 50 : width) : width}px`,
        maxInlineSize: `${!ignoreMinSize ? (width < 50 ? 50 : width) : width}px`,
      }}
      {...dynamicProperties}
    >
      <span className="relative z-10 inline-block align-middle">{children}</span>
      {!noResize && (
        <span className="top-0 right-1 bottom-0 absolute flex items-center z-10" onMouseDown={handleMouseDown}>
          <AiOutlineColumnWidth className={cssClassNameIcon} />
        </span>
      )}
    </th>
  )
}

interface IResizableTDProps extends DetailedHTMLProps<TdHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement> {
  children?: React.ReactNode
  noPadding?: boolean
  className?: string
  bordered?: boolean
  overflowHidden?: boolean
}

export const ResizableTD = ({ children, noPadding = false, className, bordered = false, overflowHidden = true, ...props }: IResizableTDProps) => {
  let cssClasses = Tailwindcss.mergeClasses("text-xs text-nowrap text-ellipsis relative border-b dark:border-slate-700 group/td", !noPadding ? "p-0.5" : "p-0")
  cssClasses = Tailwindcss.mergeClasses(cssClasses, className)
  cssClasses = Tailwindcss.mergeClasses(cssClasses, bordered ? "border" : "border-b")

  return (
    <td className={clsx(cssClasses, "text-ellipsis text-nowrap", overflowHidden && "overflow-hidden")} {...props}>
      {children}
    </td>
  )
}

interface PropsTr extends DetailedHTMLProps<HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement> {
  over?: boolean
  isHeader?: boolean
  // style?: any
  sticky?: boolean
  stickyAttach?: string
  children?: React.ReactNode
  className?: string
  hover?: boolean
}

export const ResizableTR = forwardRef(
  ({ hover, children, className, isHeader, sticky = false, stickyAttach = "", ...props }: PropsTr, ref: any): JSX.Element => {
    let classes = Tailwindcss.mergeClasses("border-b dark:border-slate-700", className)
    classes = Tailwindcss.mergeClasses(classes, hover ? "hover:bg-gray-100 dark:hover:bg-gray-800" : undefined)
    classes = Tailwindcss.mergeClasses(classes, isHeader ? "bg-gray-100 dark:bg-slate-800" : undefined)
    classes = Tailwindcss.mergeClasses(classes, sticky ? "sticky z-10" : "relative")
    classes = Tailwindcss.mergeClasses(classes, stickyAttach || "top-0")
    return (
      <tr ref={ref} className={classes} {...props}>
        {children}
      </tr>
    )
  },
)

export default TableResizable
