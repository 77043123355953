import { useState } from "react"
import { AxiosResponse } from "axios"
import { debounce } from "lodash"
import type {
  ResponseLoads,
  Load,
  ResponseLoad,
  Form,
  IFormUpdateStatus,
  AccessorialChargeForm,
  ResponseFreightAccharge,
  ResponseFreightStatusHistory,
  FreightStatusHistory,
  TraceForm,
  ResponseTrace,
  AccessorialChargeCarrierForm,
  LoadFilters,
  ResponseTripFreightsStatuses,
  FetchAllLoadsResponse,
  ILoadSurvey,
  IGetSurveyParams,
} from "../LoadType"
import axios from "@axios"
import axiosT4survey from "@/libs/axiosT4survey"
import { ResponseTrip } from "@/pages/Trips/TripType"
import Normalize from "@/utils/normalize"
import { EmailConfirmationForm } from "../components/EmailConfirmation"
export { default as InitState } from "../LoadInitial.state"

export const useLoads = (filters: LoadFilters = {}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [loads, setLoads] = useState<Load[]>([])
  const [load, setLoad] = useState<Load>()
  const [pagination, setPagination] = useState<Pagination>({} as Pagination)
  const [statusHistory, setStatusHistory] = useState<FreightStatusHistory[]>([])
  const [paginationStatusHistory, setPaginationStatusHistory] = useState<Pagination>({} as Pagination)

  const fetchAll = async (_params: LoadFilters = filters): Promise<FetchAllLoadsResponse> => {
    try {
      setLoading(true)
      const params: LoadFilters = Normalize.removePropertiesWithEmptyString(_params)
      const normalized = { ...params }
      if (params.terminal_id == "0") delete normalized.terminal_id
      const response = await axios.get<ResponseLoads>(`freights`, { params: normalized })
      const { status, data, pagination } = response.data
      if (status) {
        setPagination(pagination)
        if (params.type) {
          const adapted: Load[] = data.map((c) => ({ ...c, tx_type: params.type === "Pickup" ? "P" : "D" })) as Load[]
          setLoads(adapted)
          return { freights: adapted, pagination }
        } else {
          setLoads(data)
        }
        return { freights: data, pagination }
      }
      return { freights: [], pagination }
    } catch (error) {
      return { freights: [], pagination }
    } finally {
      setLoading(false)
    }
  }

  const fetchByBillNumber = async (bill_number: string): Promise<Load> => {
    try {
      const { data: res } = await axios.get<ResponseLoads>(`freights`, { params: { bill_number } })
      const { status, data = [] } = res
      if (status && data[0]) return data[0]
      throw "Bill number not found"
    } catch (error) {
      throw "Bill number not found"
    }
  }

  const debounceFetchAll = debounce(fetchAll, 350)

  const findOne = async (loadId: string, params = {}): Promise<AxiosResponse<ResponseLoad>> => {
    return await axios.get<ResponseLoad>(`/freights/${loadId}`, { params })
  }

  const store = async (payload: Form): Promise<AxiosResponse<ResponseLoad>> => {
    return await axios.post<ResponseLoad>(`freights`, payload)
  }

  const update = async (freightId: number, payload: Form): Promise<AxiosResponse<ResponseLoad>> => {
    return await axios.put<ResponseLoad>(`freights/${freightId}`, payload)
  }

  const updateStatus = async (payload: IFormUpdateStatus): Promise<AxiosResponse<ResponseLoad>> => {
    return await axios.post<ResponseLoad>(`freight-change-status`, payload)
  }

  const destroy = async (freightId: number) => {
    try {
      setLoading(true)
      await axios.delete<ResponseDelete>(`freights/${freightId}`)
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  const fetchStatusHistory = async (tripId?: number, queryParams?: Record<string, any>): Promise<AxiosResponse<ResponseTripFreightsStatuses>> => {
    let uri = "trip-freights-statuses"
    if (tripId) uri += `/${tripId}`
    return await axios.get<ResponseTripFreightsStatuses>(uri, {
      params: Normalize.removePropertiesWithEmptyString(queryParams),
    })
  }

  // 👉 Accessorial Accharge
  const storeFreightAccharge = async (payload: AccessorialChargeForm): Promise<AxiosResponse<ResponseFreightAccharge>> => {
    return await axios.post<ResponseFreightAccharge>(`freight-accharge`, payload)
  }

  const updateFreightAccharge = async (freightAcchargeId: number, payload: AccessorialChargeForm): Promise<AxiosResponse<ResponseFreightAccharge>> => {
    return await axios.put<ResponseFreightAccharge>(`freight-accharge/${freightAcchargeId}`, payload)
  }

  const destroyFreightAccharge = async (freightAcchargeId: number): Promise<AxiosResponse<ResponseDelete>> => {
    try {
      setLoading(true)
      return await axios.delete<ResponseDelete>(`freight-accharge/${freightAcchargeId}`)
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  // 👉 Traces
  const storeFreightTrace = async (payload: TraceForm): Promise<AxiosResponse<ResponseTrace>> => {
    return await axios.post<ResponseTrace>(`traces`, payload)
  }

  const updateFreightTrace = async (freightAcchargeId: number, payload: TraceForm): Promise<AxiosResponse<ResponseTrace>> => {
    return await axios.put<ResponseTrace>(`traces/${freightAcchargeId}`, payload)
  }

  const destroyFreightTrace = async (freightAcchargeId: number) => {
    try {
      setLoading(true)
      await axios.delete<ResponseDelete>(`traces/${freightAcchargeId}`)
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  // 👉 Freight Status History
  const fetchFreightStatusHistory = async ({ freightId, page = 1 }: { freightId: number; page?: number }) => {
    try {
      const { data: res } = await axios.get<ResponseFreightStatusHistory>(`freight-status-history/${freightId}`, {
        params: {
          per_page: 10,
          page,
        },
      })
      const { status, data, pagination } = res
      if (status) {
        setPaginationStatusHistory(pagination)
        setStatusHistory(data)
      }
    } catch (error) {}
  }

  // 👉 Copy Load
  const copyFreight = async (payload: { freight_id: number; options: string[] }): Promise<AxiosResponse<ResponseLoad>> => {
    return await axios.post<ResponseLoad>(`copy-freight`, payload)
  }

  // 👉 Assigns
  const assignCarrier = async (payload: any): Promise<AxiosResponse<ResponseLoad>> => {
    return await axios.post<ResponseLoad>(`assign-carrier-to-freight`, payload)
  }

  const updateAssignCarrier = async (payload: any): Promise<AxiosResponse<ResponseLoad>> => {
    return await axios.post<ResponseLoad>(`update-carrier-to-freight-charges`, payload)
  }

  const unassignCarrier = async (payload: any): Promise<AxiosResponse<ResponseLoad>> => {
    return await axios.post<ResponseLoad>(`unassign-carrier-to-freight`, payload)
  }

  const assign = async (payload: any): Promise<AxiosResponse<ResponseTrip>> => {
    return await axios.post<ResponseTrip>(`assign_to_trip`, payload)
  }

  const unassign = async (payload: any): Promise<AxiosResponse<ResponseTrip>> => {
    return await axios.post<ResponseTrip>(`unassign_to_trip`, payload)
  }

  // 👉 X-Charges
  const storeXCharge = async (payload: AccessorialChargeCarrierForm): Promise<AxiosResponse<ResponseLoad>> => {
    return await axios.post<ResponseLoad>(`add-charge-carrier-to-freight`, payload)
  }

  const updateXCharge = async (payload: AccessorialChargeCarrierForm): Promise<AxiosResponse<ResponseLoad>> => {
    return await axios.post<ResponseLoad>(`update-charge-carrier-to-freight`, payload)
  }

  const destroyXCharge = async (payload: { x_charge_id: number; freight_id: number }): Promise<AxiosResponse<ResponseLoad>> => {
    return await axios.post<ResponseLoad>(`remove-charge-carrier-to-freight`, payload)
  }

  const updateEmailConfirmation = async (payload: EmailConfirmationForm): Promise<AxiosResponse<ResponseLoad>> => {
    return await axios.post<ResponseLoad>(`freigth-email-docs`, payload)
  }

  // 👉 Generate Invoice Document
  const generateInvoice = async (freight_id: number) => {
    return await axios.post(`/generate-invoice`, { freight_id })
  }

  const generateBol = async (freight_id: number) => {
    return await axios.post(`/generate-bol`, { freight_id })
  }

  // 👉 Generate Rate Confirmation Document
  const generateRateConfirmation = async (freight_id: number, freight_carrier_id: number) => {
    return await axios.post(`/generate-rate-confirmation`, { freight_id: freight_id, freight_carrier_id: freight_carrier_id })
  }

  const getSurvey = async (params: IGetSurveyParams): Promise<AxiosResponse<TResponsePaginationV2<ILoadSurvey[]>>> => {
    return await axiosT4survey.get(`/api/survey`, { params })
  }

  return {
    fetchStatusHistory,
    debounceFetchAll,
    findOne,
    setLoads,
    loads,
    setLoading,
    loading,
    fetchAll,
    store,
    destroy,
    update,
    pagination,
    load,
    setLoad,
    updateStatus,
    // 👉 Accessorial Accharge
    storeFreightAccharge,
    updateFreightAccharge,
    destroyFreightAccharge,
    // 👉 Freight Status History
    fetchFreightStatusHistory,
    statusHistory,
    paginationStatusHistory,
    // 👉 Traces
    storeFreightTrace,
    updateFreightTrace,
    destroyFreightTrace,
    // 👉 Copy Load
    copyFreight,
    // 👉 Assigns
    assignCarrier,
    updateAssignCarrier,
    assign,
    unassign,
    unassignCarrier,
    // 👉 X-Charges
    storeXCharge,
    destroyXCharge,
    updateXCharge,
    // 👉 Generate Invoice Document
    generateInvoice,
    // 👉 Generate Rate Confirmation Document
    generateRateConfirmation,
    fetchByBillNumber,
    updateEmailConfirmation,
    generateBol,
    getSurvey,
  }
}
